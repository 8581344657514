<template>
  <div>
    <vehicle-selector v-if="!vehicle.auto_found"/>
    <b-row class="justify-content-around">
      <b-col lg="4" v-if="hasFullAccessOrder">
        <div class="order-title">
          <h2>
            ECU
            <hr>
          </h2>
        </div>
        <ecu-tcu-section @proceedToOptionSelector="$emit('proceedToOptionSelector', $event)" type="ecu"/>
      </b-col>
      <b-col v-show="showCombosSection && hasFullAccessOrder">
        <div class="order-title">
          <h4>
            COMBO
            <hr>
          </h4>
        </div>
        <combo-section @proceedToOptionSelector="$emit('proceedToOptionSelector', $event)" ref="combo"
                       @switchView="showCombosSection = $event"/>
      </b-col>
      <b-col lg="4">
        <div class="order-title">
          <h2>
            DSG
            <hr>
          </h2>
        </div>
        <ecu-tcu-section @proceedToOptionSelector="$emit('proceedToOptionSelector', $event)" type="tcu"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import EcuTcuSection from "@/views/dashboard/create-software-order-file/components/EcuTcuSection";
import ComboSection from "@/views/dashboard/create-software-order-file/components/ComboSection";
import VehicleSelector from "@/views/dashboard/create-software-order-file/components/VehicleSelector";

export default {
  name: "CustomOrder",
  components: {VehicleSelector, ComboSection, EcuTcuSection},
  data: () => ({
    showCombosSection: false,
  }),
  computed: {
    ...mapState('orderSoftwareFile', {
      vehicle: state => state.vehicle,
      forCustomOrder: state => state.forCustomOrder,
      dropdowns_filter: state => state.dropdowns_filter,
      hasTCUOptions: state => state.forCustomOrder.tcu.length,
      isVehicleLoaded: state => Boolean(state.vehicle.id),
    }),
    ...mapGetters('buySale', ['hasFullAccessOrder']),
  },
  methods: {
    ...mapActions('orderSoftwareFile', ['forCustomOrderByVehicleId']),
  },
  mounted() {
    if (this.vehicle.id && this.vehicle.auto_found) {
      this.forCustomOrderByVehicleId(this.vehicle.id);
    }
  },
  watch: {
    dropdowns_filter: {
      handler() {
        this.showCombosSection = false;
      },
      deep: true,
    },
  }
}
</script>

<style scoped lang="scss">

</style>
