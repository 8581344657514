<template>
  <div :class="{'fixed-right': floating}" v-if="active">
    <b-card body-class="p-0">
      <template v-for="(item, index) of serverFiles">
        <tuned-file-view-small :item="item" body-class="p-2" @remove="removeServerFile(index)"/>
      </template>
      <template v-if="hasCustomPart">
        <tuned-file-view-small
          :item="{title: `Custom`, stage: customPart.stage, price: customPart.price, file_type: customPart.type}"
          body-class="p-2" @remove="removeCustom"/>
      </template>
      <b-card body-class="p-2">
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between" v-if="total !== subtotal">
            <strong>Subtotal</strong>
            <span>
              <order-price :total="userCountryPrice(subtotal)"/>
            </span>
          </b-list-group-item>
          <template v-if="comboFound && total !== 9999">
            <b-list-group-item class="d-flex justify-content-between">
              <strong>Combo found</strong>
              <span>{{ comboFound.ecu | stage }} / {{ comboFound.dsg | stage }}</span>
              <span><currency-price-view :amount="userCountryPrice(comboFound.price)"/></span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between">
              <strong>Discount</strong>
              <span>{{ discount }}%</span>
            </b-list-group-item>
          </template>
          <b-list-group-item class="d-flex justify-content-between">
            <strong>Total</strong>
            <span>
                <order-price :total="userCountryPrice(total)"/>
              </span>
          </b-list-group-item>
        </b-list-group>
        <div class="text-right" v-if="!hasCustomPart && canOrder">
          <b-button @click="proceedToOrder" class="mt-2 red-pulse" variant="danger" style="border-radius: 0">
            Order
          </b-button>
        </div>
        <template v-else-if="!canOrder">
          <div v-if="reachedCreditLimit" class="text-danger text-center font-italic p-3">
            You have reached your credit limit
            <credits-view :amount="allowOrder.limit"/>
            . Please, complete previous orders to continue.
          </div>
          <div v-else-if="cannotCreateNoBalance">
            <b-button v-if="$userPermission(true,'purchase', 'purchase')" variant="danger" class="pull-right"
                      :to="{name:'credit-dash-received', query: {tab: 'buy'}}" style="color: white"> You don't have
              enough
              balance. Buy Credits
            </b-button>
            <p v-else class="text-danger">You don't have enough balance. Contact your distributor.</p>
          </div>
          <div v-else class="text-danger text-center font-italic p-3">
            You are not authorized to place orders
          </div>
        </template>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import TunedFileViewSmall from "@/components/SoftwareOrders/TunedFileViewSmall";
import CurrencyPriceView from "@/components/common/CurrencyPriceView";
import OrderPrice from '@/views/dashboard/create-software-order-file/components/OrderPrice';
import {UserRole} from "@/utils/auth.roles";
import CreditsView from "@/components/common/CreditsView";

export default {
  name: "OrderResume",
  components: {CreditsView, CurrencyPriceView, TunedFileViewSmall, OrderPrice},
  data: () => ({
    floating: true,
    active: true
  }),
  computed: {
    ...mapState('orderSoftwareFile', {
      serverFiles: state => state.order.serverFiles,
      combos: state => state.forCustomOrder.combos,
    }),
    ...mapGetters('geo', ['userCountryPrice']),
    ...mapGetters('orderSoftwareFile', ['subtotal', 'total', 'comboFound', 'discount', 'customPart', 'hasCustomPart', 'canCreateOrder', 'reachedCreditLimit', 'cannotCreateNoBalance']),
    ...mapState('buySale', {
      allowOrder: state => state.information.allowedOrderNoBalance,
      balance: state => state.information.balance
    }),
    canOrder() {
      if (this.$userCan(UserRole.Support)) return true;
      if (!this.$checkSubUserPermission('order_software.create-order')) return false;
      return this.canCreateOrder
    }
  },
  methods: {
    ...mapMutations('orderSoftwareFile', ['removeOrderServerFile', 'removeCustomPart']),
    removeServerFile(index) {
      this.removeOrderServerFile(index);
      this.$eventBus.$emit('scroll', 'file-list');
      if (!this.serverFiles.length && this.hasCustomPart)
        this.removeCustomPart();
    },
    removeCustom() {
      this.removeCustomPart();
      this.$eventBus.$emit('scroll', 'file-list');
    },
    proceedToOrder() {
      this.$emit('proceedToOrder', {
        original_price: this.subtotal,
        price: this.userCountryPrice(this.total)
      })
    },
    open() {
      this.active = true;
    }
  }
}
</script>

<style>
.fixed-right {
  z-index: 2 !important;
}
</style>
