<template>
  <b-card body-class="p-2" class="no-shadow rounded-0">
    <b-row>
      <b-col lg="3" :order="type === 'tcu' && filterRight? '2':'1'">
        <div class="filter">
          filter
        </div>
        <SideFilter :class="sideFilterDisabled()" ref="sideFilter" :type="type"/>
      </b-col>
      <b-col lg="9" :order="type === 'tcu' && filterRight? '1':'2'">
          <b-row v-if="(type === 'ecu' && $store.state.orderSoftwareFile.item_seeing_Ecu === null) ||
                      (type === 'tcu' && $store.state.orderSoftwareFile.item_seeing_Tcu === null)">
          <b-col>
            <b-table :fields="['title','stage','price','see_details']" :items="visible_list" bordered responsive small hover>
              <template #cell(price)="{item}">
                <currency-price-view :amount="userCountryPrice(item.price)"
                                     hide-decimals/>
              </template>
              <template #cell(stage)="{item}">{{ stages.find(el => el.value === item.stage).title }}</template>
              <template #cell(see_details)="{item}">
                <b-button @click="setItemSeeing(item)" squared variant="outline-dark">
                  See more ...
                </b-button>
              </template>
            </b-table>
            <b-alert :show="!visible_list.length" variant="gray">
              <strong>Ups</strong>, sorry we do not have any file with your filter request. Please
              <span style="text-decoration: underline; cursor: pointer" @click="removeFilters">remove filter</span> or
              <span style="text-decoration: underline; cursor: pointer" @click="toCustomOrder">
                try custom order
              </span>
            </b-alert>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <file-detail :is-in-cart="isTypeOnCart" :item="type === 'ecu'?$store.state.orderSoftwareFile.item_seeing_Ecu:$store.state.orderSoftwareFile.item_seeing_Tcu" @back="back" @procedToOrder="proceedToOrder"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Vue from 'vue';
import FileDetail from './FileDetail.vue';
import ModalContinue from '../ModalContinue.vue';
import SideFilter from './SideFilter.vue';
import {mapGetters, mapMutations, mapState} from 'vuex';
import CurrencyPriceView from '../../../../components/common/CurrencyPriceView.vue';
export default Vue.extend({
  name: 'FileList',
  components: {SideFilter, ModalContinue, FileDetail, CurrencyPriceView},
  props: {
    type: {
      type: String,
      required: true,
    },
    filterRight: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    stages: [
      {title: 'DRIVABILITY', value: 'Stock', show: true},
      {title: 'STAGE I', value: '1', show: true},
      {title: 'STAGE II', value: '2', show: true},
      {title: 'STAGE II+', value: '2.1', show: true},
      {title: 'STAGE III', value: '3', show: true},
      {title: 'STAGE IV', value: '4', show: true},
    ],
    tab_selected: null,
    result: null,
    // item_seeing: null,
  }),
  computed: {
    ...mapGetters('geo', ['userCountryPrice']),
    ...mapState('orderSoftwareFile', {
      filter: state => state.filter,
      file_on_server: state => state.file_on_server,
      orderFiles: state => state.order.serverFiles,
      comboRules: state => state.comboRules,
      combos: state => state.combos.length ? state.combos : state.forCustomOrder.combos,
      order: state => state.order
    }),
    isTypeOnCart() {
      return this.order.serverFiles.find(el => el.file_type.toLowerCase() === this.type) !== undefined;
    },
    pair_file() {
      const to_find = this.type === 'tcu' ? 'ECU' : 'TCU';
      const inFile = this.orderFiles.find(el => el.file_type === to_find);
      if (inFile) {
        return inFile;
      }
      return null;
    },
    pair_combos() {
      const pair = this.pair_file;
      if (!pair) {
        return [];
      }
      const to_find = this.type === 'tcu' ? 'ecu' : 'dsg';
      const this_type_in_combo = this.type === 'tcu' ? 'dsg' : 'ecu';
      const stages = [];
      this.combos.forEach(el => {
        if (el[to_find].toString() === pair.stage.toString()) {
          stages.push(el[this_type_in_combo].toString());
        }
      });
      return stages;
    },
    visible_list() {
      const pair = this.pair_file;
      const filter = { // filter by pair file on cart
        ecu: (file) => { // if this is ecu type and pair is tcu -> get max nm from rules
          if (!pair)
            return true;
          const {stage} = pair;
          const ruleFor = this.comboRules.find(el => el.ecu === stage);
          if (!ruleFor)
            return true;
          return file.nm <= ruleFor.max || ruleFor.max === 0;
        },
        tcu: (file) => { // if this type is tcu then
          if (!pair)
            return true;
          const {nm, stage} = pair;
          const rulesFor = this.comboRules.filter(el => Number(nm) <= el.max || el.max === 0);
          if (!rulesFor.length)
            return true;
          else {
            const stages = rulesFor.map(el => el.ecu);
            return stages.includes(file.stage)
          }
        },
      };
      const filterByOptionsInSideBar = el => {
        return !this.filter[this.type].options.length ||
          this.filter[this.type].options.every(option => {
            if (typeof option === 'object') {
              return el.options.filter(el => isNaN(el))
                .find(opt => {
                  const [val] = Object.entries(option);
                  const [key, values] = val;
                  return opt[key] && values.includes(opt[key]);
                });
            } else
              return el.options.includes(option);
          });
      }
      if((pair && pair.stage !== 'Stock') || pair === null){
        return this.file_on_server[this.type].files
          .filter(el => {
            if (this.pair_combos.length) {
              return this.pair_combos.includes(el.stage);
            }else{
              return !this.filter[this.type].stages.length || this.filter[this.type].stages.includes(el.stage); // filter by stages filter in sidebar
            }
          })
          .filter(filter[this.type])
          .filter((file) => filterByOptionsInSideBar(file));
      }else{return []}
    },
  },
  methods: {
    ...mapMutations('orderSoftwareFile', [
      'setFilesOnServer',
      'setProceedAs'
    ]),

    sideFilterDisabled() {
      const isEcuDisabled = this.$store.state.orderSoftwareFile.item_seeing_Ecu;
      const isTcuDisabled = this.$store.state.orderSoftwareFile.item_seeing_Tcu;
      let disabledSections = document.querySelectorAll('.side-filter');

      if (disabledSections.length === 1) {
        const checkboxes = disabledSections[0].querySelectorAll('.custom-control-input');
        checkboxes.forEach((checkbox) => {
          checkbox.disabled = isEcuDisabled || isTcuDisabled;
        });

        if (isEcuDisabled || isTcuDisabled) {
          disabledSections[0].classList.add('disabled_section');
        } else {
          disabledSections[0].classList.remove('disabled_section');
        }
      } else {
        disabledSections.forEach((disabledSection, index) => {
          const checkboxes = disabledSection.querySelectorAll('.custom-control-input');
          checkboxes.forEach((checkbox) => {
            checkbox.disabled = (isEcuDisabled && index === 0) || (isTcuDisabled && index === 1);
          });

          if ((isEcuDisabled && index === 0) || (isTcuDisabled && index === 1)) {
            disabledSection.classList.add('disabled_section');
          } else {
            disabledSection.classList.remove('disabled_section');
          }
        });
      }
    },

    back() {
      // this.item_seeing = null;
      if(this.type === 'ecu'){
        this.$store.state.orderSoftwareFile.item_seeing_Ecu = null
      }else{
        this.$store.state.orderSoftwareFile.item_seeing_Tcu = null
      }
      this.$eventBus.$emit('filter');
    },
    setItemSeeing(item) {
      // this.item_seeing = item;
      if(this.type === 'ecu'){
        this.$store.state.orderSoftwareFile.item_seeing_Ecu = item
      }else{
        this.$store.state.orderSoftwareFile.item_seeing_Tcu = item
      }
      this.$eventBus.$emit('filter', {
        type: this.type,
        id: item.stage,
      });
    },
    removeFilters() {
      this.$store.commit('orderSoftwareFile/setFilter', {
        options: [],
        stages: [],
      });
      this.$refs.sideFilter.clear();
    },
    toCustomOrder() {
      this.setProceedAs('custom');
    },
  },
});
</script>

<style lang="scss">
.custom-tab-2 {
  .nav-link {
    text-align: left;
    border-radius: 0;
    border: none;
    font-size: 1.125rem;
    padding: 1rem 0.625rem;
    &:hover {
      border: none;
    }
  }
  .nav-link.active {
    background: #0b0b0b !important;
    color: #ffffff !important;
    font-weight: bold;
  }
  .nav-link:not(.active) {
    background: #d14343 !important;
    color: #ffffff !important;
    font-weight: normal;
  }
  .nav-link.disabled {
    background: #d55959 !important;
    color: #e8e8e8 !important;
  }
}
.filter {
  font-weight: bold;
  background: #0b0b0b;
  color: white;
  text-transform: uppercase;
  font-size: 1.125rem;
  padding: 1rem 0.625rem;
}
.alert-gray {
  background: #f6f6f6;
  text-align: center;
  font-size: 1.1em;
}
.disabled_section{
  pointer-events: none;
  opacity: 0.4;
}
</style>
