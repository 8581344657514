<template>
  <b-modal ref="order" title="Order Detail" hide-footer body-class="p-4"
           @close="onClose" no-close-on-esc no-close-on-backdrop no-enforce-focus no-fade>
    <validation-observer v-slot="{handleSubmit}">
      <form @submit.prevent="handleSubmit(checkout)">
        <b-row class="my-1" no-gutters>
          <b-col sm="4"><label>VIN:</label></b-col>
          <b-col sm="8" class="text-right">
            <span>{{ VIN }}</span>
          </b-col>
        </b-row>
        <b-row class="my-1" no-gutters v-if="hasCustomPart">
          <b-col sm="4">
            <label id='urgency2'>Urgency:</label>
          </b-col>
          <b-col sm="8">
            <b-select
              v-tippy="{ placement : 'top',  arrow: true}"
              content="Please take notice: high urgency should ONLY be used in rare occasions AND for recommended options, please do not abuse."
              id='urgency'
              v-model="model.urgency"
              :options="urgency"
              class="date-range d-flex justify-content-end my-auto active"
              size="sm"
              style="width: 100%;">
            </b-select>
          </b-col>
        </b-row>
        <client-select v-model="model.client"/>
        <b-row v-if="hasEcuInfo">
          <b-col>
            <div class="d-flex justify-content-between">
              <label v-b-toggle.ecu-information class="text-bold">ECU tune information
                (optional)</label>
              <i v-b-toggle.ecu-information class="fa fa-plus" aria-hidden="true"></i>
            </div>
            <b-collapse id="ecu-information" class="mt-2">
              <ecu-tune-info v-model="ecu_info"/>
            </b-collapse>
          </b-col>
        </b-row>
        <b-row v-if="hasCustomPart">
          <b-col>
            <div class="d-flex justify-content-between">
              <label v-b-toggle.additional-info class="text-bold">Additional Information (optional)</label>
              <i v-b-toggle.additional-info class="fa fa-plus" aria-hidden="true"></i>
            </div>
            <b-collapse id="additional-info" class="mt-2">
              <validation-provider name="extradata" rules="max:100" v-slot="{errors}">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight: bold">Notes</span>
                  </div>
                  <textarea name="extradata" id="extradata" v-model="model.note"
                            class="form-control"
                            placeholder="Enter additional information"
                            type="text">
                          </textarea>
                  <span class="error_field" v-if="errors.length">{{ $t(`${errors[0]}`) }}</span>
                </div>
              </validation-provider>
            </b-collapse>
          </b-col>
        </b-row>
        <b-alert :show="isHpp" variant="light" class="p-2">
          <h5 class="text-dark m-0">Alert</h5>
          <small>
            Your order require High Performance validation. <br>
            please attach files with .tvsbkp and .tvsbkp.cert extension.
          </small>
        </b-alert>
        <validation-provider :rules="isHpp? 'required' : ''" name="Files" v-slot="{errors}">
          <input type="hidden" v-model="files">
          <b-row class="my-1" no-gutters>
            <b-input-group-addon append class="w-100 file-input" @click="$refs.file.click()">
              <b-btn type="button" ref="file" multiple="multiple" variant="btn-danger">
                <span class="material-icons">attachment</span>
              </b-btn>
              <div class="vue-tags-input form-control ti-focus disabled w-100"
                   style="min-height: 6vw; overflow-x: auto;" ref="dropFileArea">
                  <span v-for="(tag, i) in [...files, ...filesInDetails]" :key="i"
                        class="badge badge-pill badge-secondary text-light text-uppercase mb-2 border"
                        style="color: white !important;">{{ tag.name }}
                    <button aria-label="Close" class="removeFile" data-dismiss="alert" type="button"
                            v-if="files[i]"
                            @click.stop="files.splice(i , 1)">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </span>
              </div>
              <input ref="file" multiple style="display: none" type="file" @change="processFile">
            </b-input-group-addon>
            <span class="text-danger" v-if="errors.length || !isHppUpload">
              {{
                errors[0]
              }} {{
                files.length && !isHppUpload && isHpp && !errors.length ? 'Please attach files with .tvsbkp and .tvsbkp.cert extension.' : ''
              }}
            </span>
          </b-row>
        </validation-provider>
        <b-row v-if="hasToSelectVehicle" class="my-2">
          <b-col>
            <validation-provider rules="required" name="brand" v-slot="{errors}">
              <b-form-group>
                <label for="brand" class="m-0">Brand</label>
                <select id="brand" v-model="car_brand" class="form-control">
                  <option :value="null">Select Brand</option>
                  <option v-for="(item, index) in brands" :value="item.make" :key="index">{{ item.make }}
                  </option>
                </select>
                <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider rules="required" name="model" v-slot="{errors}">
              <b-form-group>
                <label for="model" class="m-0">Model</label>
                <select id="model" v-model="car_model" class="form-control">
                  <option :value="null">Select Model</option>
                  <option v-for="(item, index) in models" :value="item.model" :key="index">{{ item.model }}
                  </option>
                </select>
                <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <template v-if="vin_check.validation">
          <b-row>
            <b-col class="text-danger">
              The VIN belongs to a different model or make of
              car: {{ vin_check.validation.brand }} / {{ vin_check.validation.model }}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <validation-provider rules="required:true" name="ignore" v-slot="{errors}">
                <b-form-checkbox switch v-model="vin_check.status" :unchecked-value="null">
                  Ignore VIN check
                </b-form-checkbox>
                <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
                <h6 class="mt-2">If you want to ignore VIN validation, please make sure toggle switch button is
                  on, specify the reason and click ORDER</h6>
              </validation-provider>
            </b-col>
            <b-col>
              <b-form-group>
                <validation-provider rules="required|max:200" name="reason" v-slot="{errors}">
                  <b-form-textarea id="reason" v-model="vin_check.comment" rows="4"
                                   placeholder="Please specify the reason for ignoring VIN check"/>
                  <span class="text-danger" v-if="errors.length">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </template>
        <div class="modal-footer p-0 mt-4 pt-4 d-flex justify-content-around">
          <h3 style="color:black">
            <order-price :total="total"/>
          </h3>
          <b-btn class="buttonNormal" type="submit">
            Order
          </b-btn>
        </div>
      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import ClientSelect from '@/views/dashboard/create-software-order-file/components/ClientSelect';
import EcuTuneInfo from '@/views/dashboard/create-software-order-file/components/EcuTuneInfo';
import {URGENCY_NAMES} from '@/constants';
import OrderPrice from '@/views/dashboard/create-software-order-file/components/OrderPrice';

export default {
  name: 'ModalContinue',
  components: {
    OrderPrice,
    EcuTuneInfo,
    ClientSelect,
    CurrencyPriceView
  },
  data: () => ({
    urgency: URGENCY_NAMES,
    files: [],
    model: {
      urgency: '*',
      client: {
        name: null,
        email: null,
      },
      note: null,
    },
    ecu_info: {
      tune: null,
      power: null,
      fuel: null,
      turbo: null,
      add: null,
    },
    vin_check: {
      validation: null,
      status: null,
      comment: null,
    },
    allowExtensions: ['', 'text/plain', 'application/pdf', 'image/jpeg', 'application/x-rar-compressed', 'application/zip', 'audio/x-mod',
      'application/pkix-cert', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    hasToSelectVehicle: false,
  }),
  computed: {
    ...mapState('buySale', ['information']),
    ...mapState('orderSoftwareFile', {
      VIN: state => state.file_details.VIN,
      type: state => state.order.type,
      stage: state => state.order.stage,
      isHpp: state => state.order.serverFiles.some(file => file.is_hpp),
      vin_verified: state => state.order.vin_verified,
      vin_result: state => state.vin_search_result,
      vehicle: state => state.vehicle,
      completed_order: state => state.previous_order.completed,
      isVehicleEmpty: state => !state.vehicle.brand || !state.vehicle.model,
      brands: state => state.dropdowns_values.brands,
      models: state => state.dropdowns_values.models,
    }),
    ...mapGetters('orderSoftwareFile', ['total', 'hasCustomPart', 'filesInDetails']),
    isHppUpload() {
      const result = {
        cert: false,
        bkp: false
      };
      this.files.forEach(file => {
        if (file.name.endsWith('.tvsbkp.cert')) {
          result.cert = true;
        }
        if (file.name.endsWith('.tvsbkp')) {
          result.bkp = true;
        }
      });
      return result.cert && result.bkp;
    },
    hasEcuInfo() {
      return this.type === 'DSG' && !['Stock', 'original_file'].includes(this.stage);
    },
    car_brand: {
      get() {
        return this.vehicle.brand;
      },
      set(value) {
        this.setVehicle({brand: value});
        this.setDropdownsFilter({brand: value});
        this.loadDropdownFilter('models');
        this.vin_check.validation = null;
      }
    },
    car_model: {
      get() {
        return this.vehicle.model;
      },
      set(value) {
        this.setVehicle({model: value});
        this.vin_check.validation = null;
      }
    },
  },
  methods: {
    ...mapMutations('orderSoftwareFile', ['setOrderToCheckOut', 'addOrderFile', 'setVinVerified', 'setVehicle', 'setDropdownsFilter']),
    ...mapActions('orderSoftwareFile', ['loadCompletedPreOrder', 'loadDropdownFilter', 'loadDataCustomFromFilter']),
    checkout() {
      if (this.isHpp && !this.isHppUpload) {
        return;
      }
      const failedVin = this.checkVinVerified();
      if (!failedVin) {
        return;
      }
      if (this.vin_check.comment) {
        this.setVinVerified(this.vin_check.comment);
      }
      this.setOrderToCheckOut({
        ...this.model,
        ecu_info: this.hasEcuInfo ? this.ecu_info : null,
      });
      this.files.forEach(file => {
        this.addOrderFile(file);
      });
      this.$router.push({name: 'checkout-file'});
    },
    open() {
      this.$refs.order.show();
      if (this.isVehicleEmpty) {
        this.hasToSelectVehicle = true;
        this.loadDropdownFilter('brands');
      }
      this.$nextTick(() => {
        this.addDropZone();
        this.loadCompletedPreOrder();
      });
    },
    handleDrop(ev) {
      this.type_clicked = ev.currentTarget.type_cliked;
      if (ev.dataTransfer.items) {
        this.processFiles([...ev.dataTransfer.items].map(item => item.getAsFile()));
      } else {
        this.processFiles(ev.dataTransfer.files[0]);
      }
    },
    processFile(e) {
      const files = [...e.target.files];
      this.processFiles(files);
    },
    checkIfFileIsSelected(file) {
      const allfiles = [...this.filesInDetails, ...this.files];
      return allfiles.some(f => (f.name === file.name && f.size === file.size));
    },
    processFiles(files) {
      this.$refs.file.type = 'text';
      this.$refs.file.type = 'file';
      for (const element of files) {
        if (this.checkIfFileIsSelected(element)) {
          this.$swal({
            title: 'File already uploaded',
            text: `You have already uploaded ${element.name} file`,
            type: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#d14343',
          });
          return;
        }
        if (this.allowExtensions.includes(element.type)) {
          if (element.size <= (1024 * 1024 * 10)) {
            this.errorFile = false;
            this.files.push(element);
          } else {
            this.$swal({
              title: 'Error',
              text: this.$t('form.file_size_error_10')
                .toString(),
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#d14343',
            })
              .then();
          }
        } else {
          this.$swal({
            title: 'Error',
            text: `${element.name} is not a permitted file`,
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d14343',
          })
            .then();
        }
      }
    },
    addDropZone() {
      const dropArea = this.$refs.dropFileArea;
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });

      function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
      }

      dropArea.addEventListener('drop', this.handleDrop, false);
    },
    checkVinVerified() {
      if (this.vin_verified) { //
        return true;
      }
      if (this.vin_check.status) { // when vin vheck is switched on
        return true;
      }
      if (this.vin_result || this.completed_order) {
        const to_compare = () => {
          if (this.vin_result) {
            return {
              brand: this.vin_result.brand,
              model: this.vin_result.model,
            };
          }
          if (this.completed_order && this.completed_order.vehicle.brand && this.completed_order.vehicle.model) {
            return {
              brand: this.completed_order.vehicle.brand,
              model: this.completed_order.vehicle.model,
            };
          }
          return null;
        };
        const current_vehicle = () => {
          if (this.vehicle.brand && this.vehicle.model) {
            return {
              brand: this.vehicle.brand,
              model: this.vehicle.model,
            };
          }
          return null;
        };
        if (!to_compare() || !current_vehicle()) {
          this.setVinVerified(false);
          return true;
        }
        const result = JSON.stringify(to_compare())
          .toLowerCase() === JSON.stringify(current_vehicle())
          .toLowerCase();
        if (!result) {
          this.vin_check.validation = to_compare();
          return false
        } else {
          this.setVinVerified(true);
        }
      } else {
        this.setVinVerified(false);
      }
      return true;
    },
    onClose() {
      this.vin_check = {
        validation: null,
        status: null,
        comment: null,
      };
    }
  },
};
</script>

<style scoped lang="scss">
.text-bold {
  margin-bottom: 0.9vw;
  font-weight: bold;
}

.removeFile {
  font-size: 1.3em;
  color: #fff;
  cursor: pointer;
  background: transparent;
  border: none;
}

.buttonNormal {
  background-color: transparent !important;
  border-style: solid !important;
  border-color: black !important;
  border-width: 1px !important;
  font-weight: bold;
  height: 2vw;
  padding: 0;
  // border-radius: 2em !important;
  color: rgb(0, 0, 0) !important;
  width: 50%;
  min-width: 3vw !important;
}

.buttonNormal:hover {
  padding: 0;
  background: black !important;
  border-style: solid !important;
  border-width: 2px !important;
  color: white !important;
}

.file-input {
  .btn {
    border: 1px solid #e1e5eb;
    border-radius: 5px 0 0 5px;
  }

  & > .vue-tags-input {
    border-radius: 0 5px 5px 0;
    border-left-width: 0;
  }
}

h3 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 300;
}

h6 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
</style>
