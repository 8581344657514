<template>
  <div>
    <vehicle-selector v-if="showVehicleSelector"/>
    <b-row class="d-flex justify-content-center">
      <b-col v-if="file_details.ecu.file" :lg="calculateSize">
        <div class="order-title">
          <h2>
            ECU
            <hr>
          </h2>
        </div>
        <file-list type="ecu" v-if="file_on_server.ecu.files.length"/>
        <ecu-tcu-section v-else type="ecu" @proceedToOptionSelector="proceedToOptionSelector"/>
      </b-col>
      <b-col v-if="file_details.tcu.file" :lg="calculateSize">
        <div class="order-title">
          <h2>
            DSG
            <hr>
          </h2>
        </div>
        <file-list type="tcu" v-if="file_on_server.tcu.files.length" :filter-right="file_details.ecu.file"/>
        <ecu-tcu-section v-else type="tcu" @proceedToOptionSelector="proceedToOptionSelector"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import FileList from '@/views/dashboard/create-software-order-file/components/FileList';
import EcuTcuSection from '@/views/dashboard/create-software-order-file/components/EcuTcuSection';
import VehicleSelector from '@/views/dashboard/create-software-order-file/components/VehicleSelector';

export default {
  name: 'FileServerOrder',
  components: {
    VehicleSelector,
    EcuTcuSection,
    FileList
  },
  computed: {
    ...mapState('orderSoftwareFile', {
      file_details: state => state.file_details,
      file_on_server: state => state.file_on_server,
      vehicle: state => state.vehicle,
      vinSearchResult: state => state.vin_search_result,
    }),
    ...mapGetters('orderSoftwareFile', ['is_combo']),
    calculateSize() {
      return this.file_details.ecu.uploaded && this.file_details.tcu.uploaded ? 6 : 12;
    },
    showVehicleSelector() {
      return (!this.vinSearchResult || (this.vinSearchResult && !this.vinSearchResult.id)) && (!this.file_on_server.ecu.files.length && this.is_combo);
    }
  },
  methods: {
    proceedToOptionSelector(params) {
      this.$emit('proceedToOptionSelector', params);
    }
  }
};
</script>

<style scoped>

</style>
