<template>
  <b-modal v-model="active" hide-footer title="Attention !" header-class="custom-header" body-class="pt-0">
    <template v-if="result" class="">
      <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex;">
        <div class="swal2-icon-content">!</div>
      </div>
      <h6 class="text-center text-danger">No tuning available for
        {{ result.hardware_number }}_{{ result.software_version }}</h6>
      <hr>
      <div>
        Please follow the instructions below. <br>
        <div v-html="result.client_instructions"/>
      </div>
      <hr>
      <div>
        Therefore you can proceed to a
        <span @click="proceedToCustom" style="text-decoration: underline; cursor: pointer">
          custom software order
        </span>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "Instructions",
  data: () => ({
    active: false,
    result: null,
    show: false,
  }),
  methods: {
    open(data) {
      this.active = true;
      this.result = data;
    },
    proceedToCustom() {
      this.active = false;
      this.$emit('custom');
    }
  }
}
</script>

<style lang="scss">
.custom-header {
  .modal-title {
    padding-left: 1em;
    width: 100%;
    text-align: center;
  }

  .close {
    position: absolute;
    right: 1em;
  }
}
</style>
