<template>
  <transition name="show">
    <transition-group name="fade-out-in" tag="div" mode="in">
      <div v-for="(item, index) in values" :key="index"
           class="box-container"
           :class="[isPreselected(item)? 'highlight' : 'box']"
           @click="preShowDetail(item)"
           :id="`${type}Stage${item.id}`">
        <div class="content">
          <div>
            <div>
              <div class="d-flex">
                <img v-if="item.id === 'original_file'" class="staging"
                     :src="require('@/assets/images/stages/stock_white.svg')" alt="Stock file">
                <img v-else-if="item.id ==='Stock'" class="staging"
                     :src="require('@/assets/images/stages/drivability.svg')" alt="drivability">
                <img v-else alt="Stage" class="staging" :src="require('@/assets/images/stages/STAGE.svg')">
                <img v-if="item.img" alt="Card image" class="staging" :src="item.img">
              </div>
            </div>
            <b-row>
              <b-col cols="8">
                <div v-if="type === 'ecu'">
                  <template v-if="item.power !== 'On request'">
                    <span class="lightGray">Max.</span>
                    <span class="darkGray">{{ item.power }}</span>
                    <span class="lightGray">hp </span>
                  </template>
                  <span v-else class="lightGray">Hp {{ item.power }}</span>

                  <span>&nbsp; |&nbsp;&nbsp;</span>

                  <template v-if="item.torque !== 'On request'">
                    <span class="darkGray">{{ item.torque }}</span>
                    <span v-if="item.torque !== ''" class="lightGray">Nm</span>
                  </template>
                  <span v-else class="lightGray">Nm {{ item.torque }}</span>
                </div>
                <div v-else>
                  <span class="lightGray"
                        style="font-size: 0.8vw;">{{
                      item.id === 'original_file' ? 'Original file for' : 'Max. torque capacity'
                    }}</span>
                  <span class="darkGray" style="font-size: 1.2vw;"> {{ item.torque }}</span>
                </div>
              </b-col>
              <b-col>
                <div v-if="item.price !== ''" class="darkGray circle price_cont">
                  <currency-price-view v-if="!isNaN(item.price)" :amount="userCountryPrice(item.price)"
                                       hide-decimals/>
                  <span v-else>On request</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div :class="{'hid-box': !isPreselected(item)}">
          <div class="image__title">{{ item.level }}</div>
          <div class="d-flex justify-content-between">
            <p class="image__description">
              {{ item.data }}
            </p>
            <div class="ml-2 mt-auto" v-if="isPreselected(item)">
              <b-button v-if="canOrder" variant="outline-light" class="continue"
                        v-tippy="{ placement : 'top',  arrow: true }"
                        :content="`Order only with ${type.toUpperCase()} selected`"
                        @click.stop="proceedToOptionSelector(item)">
                ORDER
              </b-button>
              <div v-else class="text-center text-danger">
                First select a file-server tune to continue.
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </transition>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import CurrencyPriceView from "@/components/common/CurrencyPriceView";

export default {
  name: "EcuTcuSection",
  components: {CurrencyPriceView},
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    preselected_id: null,
    filter_click: false,
  }),
  computed: {
    ...mapGetters('geo', ['userCountryPrice']),
    ...mapState('orderSoftwareFile', {
      forCustomOrder: state => state.forCustomOrder,
      storeCombos: state => state.forCustomOrder.combos,
      hasFile: state => state.file_on_server.ecu.stock || state.file_on_server.tcu.stock,
      isFileInCart: state => Boolean(state.order.serverFiles.length),
      proceedAs: state => state.proceedAs,
      serverFiles: state => state.order.serverFiles,
    }),
    canOrder: function () {
      if (this.proceedAs === 'custom') {
        return true;
      }
      return !!(this.hasFile && this.isFileInCart);
    },
    values: function () {
      return this.forCustomOrder[this.type].filter(item => {
        return !this.filter || this.filter.includes(item.id);
      });
    },
    otherSide: function () {
      return this.type === 'ecu' ? 'dsg' : 'ecu';
    },
    filter: function () {
      const otherSideFile = this.serverFiles
        .find(file => file.file_type.toLowerCase() === this.otherSide);
      const stage = () => {
        if (otherSideFile) {
          return otherSideFile.stage;
        }
        return this.filter_click.id;
      }
      if (otherSideFile || this.filter_click)
        return this.storeCombos
          .filter(combo => combo[this.otherSide] === stage())
          .map(item => item[this.type.replace('tcu', 'dsg')]);

      return false
    },
  },
  methods: {
    isPreselected(item) {
      if (this.preselected_id) {
        return this.preselected_id === item.id;
      }
      return false;
    },
    preShowDetail(item) {
      this.preselected_id = item.id;
      this.$eventBus.$emit('fill-combos', {
        id: item.id,
        type: this.type,
      });
    },
    filterFromServerFiles(option) {
      const {id, type} = option;
      this.filter = this.storeCombos.filter(combo => {
        return combo[type] === id;
      }).map(item => {
        const field = type === 'ecu' ? 'dsg' : 'ecu';
        return item[field];
      });
    },
    checkForAutoProceed() {
      if (this.values.length === 1) {
        this.proceedToOptionSelector(this.values[0]);
        this.$eventBus.$emit('scroll', 'options-editor');
      }
    },
    proceedToOptionSelector(item) {
      this.$emit('proceedToOptionSelector', {
        type: this.type.replace('tcu', 'dsg').toUpperCase(),
        id: item.id,
        price: item.price,
      });
    },
  },
  mounted() {
    this.$eventBus.$on('filter', (data) => {
      this.filter_click = data;
    });
    this.$eventBus.$on('order-combo', () => {
      this.checkForAutoProceed();
    });
  },
  watch: {
    values: function () {
      this.preselected_id = null;
    },
  },
}
</script>

<style scoped lang="scss">
.box-container {
  width: 90%;
  margin: auto;
  padding-bottom: 1vw;
}

.box {
  height: 90px;
  overflow: hidden;
  transition: all 0.3s ease 0.2s;
  cursor: pointer;
  margin: auto;
  margin-bottom: 2vw !important;
}

.hid-box {
  top: 100%;
  position: relative;
  word-wrap: break-word !important;
  transition: all 0.4s ease-out;
  background: rgba(0, 0, 0, 0.534);
  color: white;
  padding: 3px;
  margin-bottom: 1em;
}

.box:hover > .hid-box {
  top: -3vw !important;
  margin-bottom: 4vw !important;
}

.box:hover {
  transform: scale(1.1);
}

.box:hover > .content {
  opacity: 0;
  transition: all 0.5s ease-out;
}

.content {
  transition: all 0.4s ease 0.1s;
}

.staging {
  max-height: 1.2vw !important;
  filter: invert(42%) sepia(93%) saturate(0%) hue-rotate(87deg) brightness(160%) contrast(119%);

  + .staging {
    margin-left: 2vw;
  }
}

.darkGray {
  font-weight: 300;
  color: #d14343;
  font-size: 1.5em;
}

.circle {
  color: white;
  text-align: right;
  font-size: 1.6vw;
  margin-top: -.5vw;
}

.image__title {
  font-size: 0.8em;
  margin: 0;
}

.image__description {
  font-size: 0.8em;
  margin-top: 0.25em;
}

.continue {
  color: #d14343;
  border-color: #FFFFFF;
  padding: .5vw 1.5vw;
  border-radius: 0;
  margin: .1vw;

  &:hover {
    background: transparent;
  }
}

.enlighten, .highlight {
  transition: .25s;
  transform: scale(1.1);
  margin-bottom: 2vw;
}

@media only screen and (max-width: 600px) {
  .box-container {
    width: 100%;
    margin-bottom: 1em;
  }
  .box {
    height: 50px;
    margin-bottom: 5vw;
  }

  .staging {
    max-height: 2.2vw !important;
  }

  .circle {
    font-size: 1.2em;
  }
}
</style>
