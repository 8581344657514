<template>
  <transition name="show">
    <transition-group name="fade-out-in" tag="div" mode="out-in">
      <div v-for="(combo, index) of combos" :key="index">
        <div class="box-container" @mouseleave="removeElementHighlight(combo)"
             @mouseover="elementHighlight(combo)">
          <div class="content">
            <div>
              <table class="w-100">
                <tr>
                  <td class="text-center">
                    <span class="lightGray">ECU:</span>
                    <div>+</div>
                  </td>
                  <td class="text-center">
                    <img alt="Card image" class="combo-image" src="@/assets/images/stages/STG.svg">
                    <hr class="combo-hr"/>
                  </td>
                  <td style="vertical-align: sub" class="pl-3">
                    <img :src="combo.left" alt="Card image" class="combo-image">
                  </td>
                  <td class="price-combo">
                    <currency-price-view v-if="!isNaN(combo.price) && combo.price > 0 && combo.price !==9999"
                                         :amount="userCountryPrice(combo.price)" hide-decimals/>
                    <span v-else-if="combo.price === 9999">On request</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="lightGray">DSG:</span>
                  </td>
                  <td>
                    <img alt="Card image" class="combo-image" src="@/assets/images/stages/STG.svg">
                  </td>
                  <td class="pl-3">
                    <img :src="combo.right" alt="Card image" class="combo-image">
                  </td>
                  <td class="percent-combo">
                    <div v-if="combo.discount > 5 && !isNaN(combo.price) && combo.price > 0">
                      <h2>{{ combo.discount }} </h2>
                      <div>
                        <div>%</div>
                        <div>OFF</div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="mb-4">
            <div class="image__title">{{ combo.title }}</div>
            <div class="d-flex justify-content-between">
              <p class="image__description">
                {{ combo.description }}
              </p>
              <div class="ml-2 mt-auto">
                <b-button variant="outline-light" class="continue"
                          @click.stop="proceedToOptionSelector(combo)">
                  ORDER
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </transition>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import CurrencyPriceView from "@/components/common/CurrencyPriceView";

export default {
  name: "ComboSection",
  components: {CurrencyPriceView},
  data: () => ({
    combos: [],
    option: {
      id: null,
      type: null,
    },
  }),
  computed: {
    ...mapState('orderSoftwareFile', {
      storeCombos: state => state.forCustomOrder.combos,
      dropdown_filter: state => state.dropdowns_filter,
    }),
    ...mapGetters('geo', ['userCountryPrice']),
  },
  methods: {
    setCombos: function () {
      if (this.storeCombos.length && this.checkIfHasCombo()) {
        const combos = this.storeCombos;
        if (this.option) {
          let timer = 500;
          if (!this.combos.length) {
            timer = 0;
          }
          this.combos = [];
          setTimeout(() => {
            this.combos = combos.filter(combo => {
              const field = this.option.type;
              return combo[field] === this.option.id;
            });
            this.$store.commit('orderSoftware/setcombos', this.combos)
          }, timer);
        }
      }
    },
    checkIfHasCombo() {
      if (this.option) {
        const field = this.option.type;
        const combos = this.storeCombos.filter(combo => combo[field] === this.option.id);
        this.$emit('switchView', combos.length > 0);
        return combos.length > 0;
      }
      return false;
    },
    elementHighlight(element) {
      const ecuBox = document.getElementById(`ecuStage${element.ecuObject.id}`);
      if (ecuBox) {
        ecuBox.classList.add('enlighten');
      }
      const dsgBox = document.getElementById(`tcuStage${element.dsgObject.id}`);
      if (dsgBox) {
        dsgBox.classList.add('enlighten');
      }
    },
    removeElementHighlight(element) {
      const ecuBox = document.getElementById(`ecuStage${element.ecuObject.id}`);
      if (ecuBox) {
        ecuBox.classList.remove('enlighten');
      }
      const dsgBox = document.getElementById(`tcuStage${element.dsgObject.id}`);
      if (dsgBox) {
        dsgBox.classList.remove('enlighten');
      }
    },
    proceedToOptionSelector(item) {
      this.$emit('proceedToOptionSelector', {
        type: 'ECU + DSG',
        id: item.id,
        price: item.price,
      });
    },
  },
  mounted() {
    this.$eventBus.$on('fill-combos', (option) => {
      this.option = {
        type: option.type.replace('tcu', 'dsg'),
        id: option.id,
      }
      this.setCombos();
    });
  },
  watch:{
    dropdown_filter: {
      deep: true,
      handler: function () {
        this.combos = [];
      }
    }
  }
}
</script>

<style scoped lang="scss">
$light-gray: #959595;
$red: #d14343;
.box-container {
  width: 90%;
  margin: auto;
  padding-bottom: 1vw;
}

.box {
  height: 100px;
  overflow: hidden;
  transition: all 0.3s ease 0.2s;
  cursor: pointer;
  margin: auto;
  margin-bottom: 2vw !important;
}

.hid-box {
  top: 100%;
  position: relative;
  word-wrap: break-word !important;
  transition: all 0.4s ease-out;
  background: rgba(0, 0, 0, 0.534);
  color: white;
  padding: 3px;
  margin-bottom: 1em;
}

.box:hover > .hid-box {
  top: -3vw !important;
  margin-bottom: 4vw !important;
}

.box:hover {
  transform: scale(1.1);
}

.box:hover > .content {
  opacity: 0;
  transition: all 0.5s ease-out;
}

.content {
  transition: all 0.4s ease 0.1s;
}

.combo-image {
  max-height: .9vw !important;
  filter: invert(42%) sepia(93%) saturate(0%) hue-rotate(87deg) brightness(160%) contrast(119%);
}

.combo-hr {
  margin-top: 0.7vw !important;
  margin-bottom: 0.4vw !important;
  border-top: 1px solid;
  border-color: $light-gray;
  width: 80%;
}

.price-combo {
  width: 100%;
  text-align: right;
  font-size: 1.6vw;
  color: white;
}


.percent-combo {
  width: 100%;
  text-align: right;
  color: rgb(209, 67, 67) !important;

  & > div > div > div:first-child {
    font-size: 1.2vw
  }

  & > div {
    margin-right: 1.2vw;
    display: flex;
    justify-content: flex-end;

    & > div {
      margin-top: -.3vw;
    }

    & > h2 {
      color: rgb(209, 67, 67);
      padding: 0;
      margin: 0;
    }

    > div > div:last-child {
      font-size: 0.55vw;
      margin-top: -.3vw;
    }
  }
}

.image__title {
  font-size: 0.8em;
  margin: 0;
}

.image__description {
  font-size: 0.8em;
  margin-top: 0.25em;
}

.continue {
  color: #d14343;
  border-color: #FFFFFF;
  padding: .5vw 1.5vw;
  border-radius: 0;
  margin: .1vw;

  &:hover {
    background: transparent;
  }
}

@media only screen and (max-width: 600px) {
  .box-container {
    width: 100%;
    margin-bottom: 1em;
  }
  .box {
    height: 50px;
    margin-bottom: 5vw;
  }

  .staging {
    max-height: 2.2vw !important;
  }

  .circle {
    font-size: 1.2em;
  }
}
</style>
