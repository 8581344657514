<template>
  <div class="with-custom-checkbox" style="width: 100%; min-height: 100vh;" ref="page"
       :class="{'page-highlight': highlight.page}">
    <div v-rellax="{speed: -18,}" class="fondo"></div>
    <div>
      <b-container class="main-content-container px-4 pb-4" id="file-inputs" fluid>
        <b-row class="page-header py-2" no-gutters>
          <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="12">
          </b-col>
        </b-row>
        <div class="mt-5" v-if="combosToShow.length">
          <div class="text-center">
            <h3 class="title">Get a combo for ...</h3>
          </div>
          <b-row class="d-flex justify-content-center">
            <b-col lg="3" v-for="combo of combosToShow">
              <combo-view :combo="combo"/>
            </b-col>
          </b-row>
          <div class="text-center">
            <h5 class="title">Upload {{ (disabled.tcu ? 'CMD Read / TCM ID .txt' : 'TCM ID .txt') }}
              file to show available files</h5>
          </div>
        </div>
        <div style="margin-top: 5vw;">
          <b-row>
            <b-col cols="6" class="text-center">
              <div class="drop-area ml-sm-auto"
                   :class="{
                    'highlight': highlight.ecu && !disabled.ecu,
                    'call-action': file_details.tcu.file && !file_details.ecu.file && !disabled.ecu && !isProccesing,
                    disabled: disabled.ecu || !loaded || isProccesing
                    }"
                   ref="ecu_area">
                <b-button :disabled="disabled.ecu || !loaded || isProccesing"
                          @click="$refs.input.click(); type_clicked = 'ECU'"
                          data-test-id="ecu">
                  <img :src="require('@/assets/images/upload/upload-ecu.png')" alt="upload ecu">
                </b-button>
                <div class="help">
                  drop a file here to request a combo
                </div>
              </div>
            </b-col>
            <b-col cols="6" class="text-center">
              <div class="drop-area mr-sm-auto"
                   :class="{
                        'highlight': highlight.tcu && !disabled.tcu,
                        'call-action': file_details.ecu.file && !file_details.tcu.file && !disabled.tcu && !isProccesing,
                        disabled: disabled.tcu || !loaded || isProccesing
                      }"
                   ref="tcu_area">
                <b-button :disabled="disabled.tcu || !loaded || isProccesing" class="btn"
                          @click="$refs.input.click(); type_clicked = 'TCU'"
                          data-test-id="tcu">
                  <img :src="require('@/assets/images/upload/upload-tcu.png')" alt="upload ecu">
                </b-button>
                <div class="help">
                  drop a file here to request a combo
                </div>
              </div>
            </b-col>
            <input :type="type" style="display: none" ref="input" @change="selectFile">
          </b-row>
          <b-row v-show="showProcessing">
            <b-col offset-lg="3" lg="6" class="mt-3">
              <processing-file ref="processing"/>
            </b-col>
          </b-row>
        </div>
        <div id="file-list" class="mt-5">
          <div v-if="proceedAs === 'fileServer'">
            <file-server-order @proceedToOptionSelector="proceedToOptionSelector"/>
          </div>
          <custom-order v-else-if="proceedAs === 'custom'" @proceedToOptionSelector="proceedToOptionSelector"/>
          <b-row class="justify-content-center mt-3" v-show="isVehicleSelected && proceedAs !== null">
            <b-col id="options-editor" lg="9">
              <options-editor @input="setOrderItems($event)" show-price-alerts ref="optionsEditor"
                              @update-price="updatePrice" @replace-price="replacePrice"
                              class="pt-1 pb-4 px-5">
                <div class="text-center mt-5" v-if="canOrder">
                  <b-button variant="danger" style="background: black" @click="continueToModal" class="order">
                    Order
                  </b-button>
                </div>
                <template v-else>
                  <div v-if="reachedCreditLimit" class="text-danger text-center font-italic p-3">
                    You have reached your credit limit
                    <credits-view :amount="allowOrder.limit"/>
                    . Please, complete previous orders to continue.
                  </div>
                  <div v-else-if="cannotCreateNoBalance">
                    <b-button v-if="$userPermission(true,'purchase', 'purchase')" variant="danger"
                              :to="{name:'credit-dash-received', query: {tab: 'buy'}}"> <span class="text-white">You don't have enough
                      balance. Buy Credits</span>
                    </b-button>
                    <p v-else class="text-danger text-center font-italic p-3">You don't have enough balance. Contact
                      your
                      distributor.</p>
                  </div>
                  <div v-else class="text-danger text-center font-italic p-3">
                    You are not authorized to place an order
                  </div>
                </template>
              </options-editor>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <order-resume ref="orderResume" v-if="serverFilesInOrder.length" @proceedToOrder="continueToModal"/>
      <modal-continue ref="continue"/>
      <instructions ref="instructions" @custom="setProceedAs('custom')"/>
    </div>
    <b-btn v-if="$refs.optionsEditor && $refs.optionsEditor.params" id="back-top"
           v-tippy="{ placement : 'top',  arrow: true }" class="dissapear"
           content="Back to stage selector"
           title="Go to stage selector" @click="toogleBackButton()"><i
      class="material-icons"
      style="display: flex; align-items: center; justify-content: center; margin-top: -12px;">keyboard_arrow_up</i>
    </b-btn>
  </div>
</template>

<script>
import Vue from 'vue';
import {FILE_TXT_FINDER, onRequestPrice} from '@/constants';
import {post} from '@/services/api';
import FileList from '@/views/dashboard/create-software-order-file/components/FileList';
import VueRellax from 'vue-rellax';
import {mapGetters, mapMutations, mapState} from 'vuex';
import ComboView from '@/components/common/ComboView';
import OrderResume from '@/views/dashboard/create-software-order-file/components/OrderResume';
import {checksum} from '@/utils';
import Instructions from '@/views/dashboard/create-software-order-file/Instructions';
import ProcessingFile from '@/views/dashboard/create-software-order-file/ProcessingFile';
import EcuTcuSection from '@/views/dashboard/create-software-order-file/components/EcuTcuSection';
import CustomOrder from '@/views/dashboard/create-software-order-file/CustomOrder';
import OptionsEditor from '@/components/SoftwareOrders/OptionsEditor';
import ModalContinue from '@/views/dashboard/create-software-order-file/ModalContinue';
import FileServerOrder from '@/views/dashboard/create-software-order-file/FileServerOrder';
import show_alert_mixin from "@/utils/show_alert_mixin";
import {UserRole} from "@/utils/auth.roles";
import CreditsView from "@/components/common/CreditsView";

Vue.use(VueRellax);

export default {
  name: 'CreateOrderByFile',
  mixins: [
    show_alert_mixin
  ],
  components: {
    CreditsView,
    FileServerOrder,
    ModalContinue,
    OptionsEditor,
    CustomOrder,
    EcuTcuSection,
    ProcessingFile,
    Instructions,
    OrderResume,
    ComboView,
    FileList
  },
  data: () => ({
    keys: FILE_TXT_FINDER,
    result: null,
    type_clicked: null,
    type: 'file',
    stock_read_result: null,
    noFound: false,
    readerError: false,
    combosToShow: [],
    disabled: {
      ecu: false,
      tcu: false
    },
    highlight: {
      ecu: false,
      tcu: false,
      page: false
    },
    showProcessing: false,
    allowedFiles: ['text/plain', '', 'chemical/x-mopac-input'],
  }),
  computed: {
    ...mapState('orderSoftwareFile', {
      file_details: state => state.file_details,
      file_on_server: state => state.file_on_server,
      proceedAs: state => state.proceedAs,
      forCustomOrder: state => state.forCustomOrder,
      serverFilesInOrder: state => state.order.serverFiles,
      VIN: state => state.file_details.VIN,
      customPart: state => ({
        type: state.order.type,
        stage: state.order.stage,
        price: state.order.price,
        fees: state.order.fees,
      }),
      instructions: state => {
        if (state.file_on_server.ecu.stock && state.file_on_server.ecu.stock.client_instructions) {
          return state.file_on_server.ecu.stock;
        }
        if (state.file_on_server.tcu.stock && state.file_on_server.tcu.stock.client_instructions) {
          return state.file_on_server.tcu.stock;
        }
        return null;
      },
    }),
    ...mapState('buySale', {
      allowOrder: state => state.information.allowedOrderNoBalance,
      loaded: state => state.loaded,
      isFileServerEnabled: state => state.file_server_enabled,
    }),
    ...mapGetters('buySale', ['hasFullAccessOrder']),
    ...mapGetters('orderSoftwareFile', ['isVehicleSelected', 'canCreateOrder', 'reachedCreditLimit', 'cannotCreateNoBalance', 'cannotCreateNoBalance']),
    isProccesing() {
      if (!this.$refs.processing)
        return false;
      return this.$refs.processing.isProccesing;
    },
    canOrder() {
      if (this.$userCan(UserRole.Support)) return true;
      if (!this.$checkSubUserPermission('order_software.create-order')) return false;
      return this.canCreateOrder
    },
  },
  methods: {
    ...mapMutations('orderSoftwareFile', [
      'setFileDetails', 'setOrderCustomTypeStage', 'setOrderItems', 'setOrderPrice', 'clear',
      'onProceedAsChange', 'clearOnVINchange', 'setOrderFees', 'removeOrderFee', 'setProceedAs'
    ]),
    selectFile(e) {
      if (e.target.files.length) {
        this.noFound = false;
        this.processFile(e.target.files[0]);
        this.type = 'text';
        this.$nextTick(() => {
          this.type = 'file';
        });
      }
    },
    async handleDropPage(ev) {
      const getFile = () => {
        if (ev.dataTransfer.items) {
          return ev.dataTransfer.items[0].getAsFile();
        } else {
          return ev.dataTransfer.files[0];
        }
      };
      const file = getFile();
      if (file) {
        if (['text/plain'].includes(file.type)) {
          const content = await this.getContentTextFile(file);
          const TCU_IDENTIFIERS = ['DSG', 'GSG', '0AM', '0CW', 'O1J', '0AW', '02E', '0D9', '0DE', '0GC', '0DD', 'OBK', '0B5', '0CK', '0BH', '0BT', '0DL']
          const searchValue = () => {
            const KEY = 'system name or engine type'
            for (const [key, value] of Object.entries(content)) {
              if (key.toLowerCase().includes(KEY)) {
                return value;
              }
            }
          }
          const isTcu = () => {
            const value = searchValue();
            if (value) {
              return TCU_IDENTIFIERS.some(identifier => value.includes(identifier));
            }
            return false;
          }
          const use = isTcu() ? 'tcu' : 'ecu';
          await this.processTextFile(file, use);
        } else {
          this.type_clicked = 'ECU';
          await this.processFile(file);
        }
      }
    },
    handleDrop(ev) {
      this.type_clicked = ev.currentTarget.type_cliked;
      if (ev.dataTransfer.items) {
        this.processFile(ev.dataTransfer.items[0].getAsFile());
      } else {
        this.processFile(ev.dataTransfer.files[0]);
      }
    },
    async processFile(file) {
      if (!this.allowedFiles.includes(file.type)) {
        await this.showAlertNotification('Invalid file type', 'error');
        return;
      }
      const use = this.type_clicked === 'ECU' ? 'ecu' : 'tcu';
      if (this.disabled[use]) return;
      if (file.type === 'text/plain') {
        await this.processTextFile(file, use);
      } else if (use === 'ecu') {
        await this.processHexFile(file, use);
      } else {
        await this.showAlertNotification(`Invalid file type`, 'error');
      }
    },
    checkToClearOposite(payload) {
      const use = payload.type === 'ecu' ? 'tcu' : 'ecu';
      if (this[`${use}_uploaded`] && this.VIN !== payload.VIN) {
        this.clear();
        let message = `The TCU file uploaded doesn't match to the engine ECU. The ECU file has been removed.`;
        if (payload.type === 'ecu') {
          message = `The engine ECU file uploaded doesn't match to the TCU. The TCU file has been removed.`;
        }
        this.$swal({
          title: 'Warning',
          text: message,
          type: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#d14343',
          timer: 5000
        });
      }
    },

    async processTextFile(file, use) {
      this.$refs.processing.searchVin();
      const fileContent = await this.getContentTextFile(file);
      const values = {};
      const search = (key) => {
        const objKeys = Object.keys(fileContent);
        for (const k of objKeys) {
          if (key.some(el => el.toLowerCase()
            .includes(k.toLowerCase()))) {
            return fileContent[k];
          }
        }
        return null;
      };
      Object.keys(this.keys[use])
        .forEach(key => {
          values[key] = search(this.keys[use][key]);
        });
      if ((values.hardware_number && values.software_version) || values.VIN) {
        const payload = {
          VIN: values.VIN,
          type: use,
          details: {
            hardware_number: values.hardware_number,
            software_version: values.software_version,
            file,
            tool: 'TCM'
          }
        };
        this.checkToClearOposite(payload);
        if (this.file_details[use].uploaded) {
          this.clearOnVINchange();
        }
        if (!payload.VIN) {
          payload.VIN = await this.requestVIN();
          if (!payload.VIN) {
            return;
          }
        }

        this.showProcessing = true;
        this.$nextTick(() => {
          this.setFileDetails(payload);
        });
      } else {
        await this.$swal({
          icon: 'error',
          title: 'Error',
          timer: 2500,
          text: 'File does not have a correct format, or required identifications were not found.',
          showConfirmButton: false
        });
      }
    },
    async processHexFile(file, use) {
      if (this[`${use}_uploaded`] && this.VIN !== null) {
        this.setFileDetails({
          VIN: null,
          type: use,
          details: {
            hardware_number: null,
            software_version: null,
            file: null,
            tool: 'CMD'
          }
        })
      }

      const VIN = await this.requestVIN();
      if (!VIN) {
        return;
      }
      const payload = {
        VIN,
        type: use,
        details: {
          hardware_number: null,
          software_version: null,
          cmd_file_key: null,
          file,
          tool: 'CMD'
        }
      };

      if (this.file_details[use].uploaded) {
        this.clearOnVINchange();
      }

      this.showProcessing = true;
      this.$nextTick(() => {
        this.setFileDetails(payload);
      });

      const previousUpload = await this.findPreviousUpload(file);
      const resolveResponse = async (response) => {
        if (response.VIN && payload.VIN !== response.VIN) {
          await this.$swal({
            title: 'Warning',
            text: `The VIN entered "${payload.VIN}" doesn't match with the VIN in the .dat file "${response.VIN}". The VIN has been replaced.`,
            type: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#d14343',
            timer: 10000
          }).then(() => {
            this.sockets.unsubscribe(payload.VIN)
            payload.VIN = response.VIN;
          });
        }
        this.checkToClearOposite(payload);
        this.setFileDetails(payload);
      }
      if (!previousUpload) {
        const form = new FormData();
        form.append('file', file);
        post(`file-processor/cmd-read-stock`, form, true)
          .then(({data}) => {
            this.sockets.subscribe(data.channel, async (response) => {
              if (response.message) {
                return;
              }
              if (response.result) {
                payload.details = {
                  ...payload.details,
                  ...response.result,
                  cmd_file_key: response.cmd_file_key,
                  file,
                };
                await resolveResponse(response)
                this.sockets.unsubscribe(data.channel);
              } else {
                this.$refs.processing.setEcuFailFind(true);
              }
            });
          })
          .catch(() => {
            this.$refs.processing.setEcuFailFind();
          });
      } else {
        payload.details = {
          ...previousUpload.result,
          cmd_file_key: previousUpload.cmd_file_key,
          file,
          tool: 'CMD'
        };
        await resolveResponse(previousUpload)
      }
    },
    async findPreviousUpload(file) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = (ev) => {
          const buffer = Buffer.from(ev.currentTarget.result);
          const checksum_res = checksum(buffer);
          post(`file-processor/check-previous-upload`, {checksum: checksum_res}, true)
            .then(({data}) => {
              if (data) {
                resolve(data);
              } else {
                resolve(false);
              }
            });
        };
        reader.readAsArrayBuffer(file);
      });
    },
    getContentTextFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = (ev) => {
          const final = {};
          const fileContent = ev.currentTarget.result.split(/\r\n|\n/);
          fileContent.forEach(line => {
            if (line.includes(':')) {
              let [key, value] = line.split(':')
                .map(el => el.trim());
              if (key.length > 3) {
                final[key] = value;
              }
            }
          });
          resolve(final);
        };
        reader.readAsText(file);
      });
    },
    /**
     * @param params {type: string, id: string} type: 'ECU' or 'DSG' id: stage id
     */
    proceedToOptionSelector(params) {
      this.$refs.optionsEditor.reset();
      this.$refs.optionsEditor.setDataForOptions({
        ecu: this.forCustomOrder.ecu,
        tcu: this.forCustomOrder.tcu,
        combos: this.forCustomOrder.combos,
      });
      this.$refs.optionsEditor.setParams(params);
      this.setOrderCustomTypeStage({
        type: params.type,
        stage: params.id,
        price: !isNaN(params.price) ? Number(params.price) : onRequestPrice,
      });
      this.scrollToID('options-editor');
    },
    async continueToModal() {
      if (this.customPart.type && (await this.$refs.optionsEditor.validate())) {
        this.$refs.continue.open();
      } else if (this.serverFilesInOrder.length && !this.customPart.type) {
        this.$refs.continue.open();
      }
    },
    scrollToID(id) {
      this.$nextTick(() => {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      });
    },
    requestVIN() {
      if (this.file_details.VIN) {
        return this.file_details.VIN;
      }
      return new Promise((resolve) => {
        this.$swal({
          title: 'Enter VIN',
          text: 'VIN not found and is required to continue',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'on', maxlength: 18
          },
          confirmButtonText: 'Confirm',
          confirmButtonColor: '#d14343',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCancelButton: true,
          preConfirm: (vin) => {
            let regex = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17,18}$/;
            let space = /^[a-zA-Z0-9_]*$/;
            if (!regex.test(vin) || !space.test(vin)) {
              return this.$swal.showValidationMessage('VIN must be 17/18 characters long.');
            }
          }
        })
          .then(({value, isConfirmed}) => {
            if (isConfirmed) {
              resolve(value.toUpperCase());
            } else {
              resolve(false);
            }
          });
      });
    },
    replacePrice(price) {
      if (price > 0) {
        this.setOrderFees(price);
      } else {
        this.removeOrderFee(price * -1)
      }
    },
    updatePrice(price) {
      if (price > 0) {
        this.setOrderFees(price);
      } else {
        this.removeOrderFee(price * -1)
      }
    },
    toogleBackButton() {
      this.scrollToID('file-list');
      this.$refs.optionsEditor.reset();
    },
  },
  watch: {
    isVehicleSelected() {
      if (!this.isVehicleSelected) {
        this.$refs.optionsEditor.reset();
      }
    },
    proceedAs: function (val) {
      if (val) {
        this.onProceedAsChange();
        this.$refs.optionsEditor.reset();
        this.scrollToID('file-list');
      }
    },
    hasFullAccessOrder: function () {
      this.disabled.ecu = !this.hasFullAccessOrder
    },
    loaded: function (loaded) {
      if (loaded) {
        if (!this.isFileServerEnabled) {
          this.$router.push({name: 'order-software'});
        }
      }
    },
    instructions: function (instructions, oldInstructions) {
      if (!oldInstructions && instructions) {
        this.$refs.instructions.open(instructions);
      }
    },
  },
  mounted() {
    this.clear();
    this.disabled.ecu = !this.hasFullAccessOrder
    const ecuArea = this.$refs.ecu_area;
    const tcuArea = this.$refs.tcu_area;
    const page = this.$refs.page;
    ecuArea.type_cliked = 'ECU';
    tcuArea.type_cliked = 'TCU';
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      tcuArea.addEventListener(eventName, preventDefaults, false);
      ecuArea.addEventListener(eventName, preventDefaults, false);
      page.addEventListener(eventName, preventDefaults, false);
    });

    function preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    }
    ;['dragenter', 'dragover'].forEach(eventName => {
      ecuArea.addEventListener(eventName, () => this.highlight.ecu = true, false);
      tcuArea.addEventListener(eventName, () => this.highlight.tcu = true, false);
      page.addEventListener(eventName, () => this.highlight.page = true, false);
    })

    ;['dragleave', 'drop'].forEach(eventName => {
      ecuArea.addEventListener(eventName, () => this.highlight.ecu = false, false);
      tcuArea.addEventListener(eventName, () => this.highlight.tcu = false, false);
      page.addEventListener(eventName, () => this.highlight.page = false, false);
    });

    ecuArea.addEventListener('drop', this.handleDrop, {});
    tcuArea.addEventListener('drop', this.handleDrop, false);
    page.addEventListener('drop', this.handleDropPage, false);


    this.$eventBus.$on('scroll', (element) => {
      this.scrollToID(element);
    });
  },
};
</script>

<style scoped lang="scss">
$red: #d14343;
$light-gray: #ff0000;

button.order {
  background-color: transparent !important;
  border-style: solid !important;
  border-color: black !important;
  border-width: 1px !important;
  font-weight: bold;
  height: 2vw;
  padding: 0;
  color: black !important;
  width: 50%;
  min-width: 3vw !important;
}


#file-list, #file-inputs, #options-editor {
  scroll-margin-top: 61px;
}

.drop-area {
  width: fit-content;
  padding: 1em 1.5em;
  border: 2px solid #939393;
  position: relative;
  border-radius: 20px;

  &.pulse {
    animation: pulse 1s infinite;
  }

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2.5%;
    color: #9d9d9d;
    content: "Drop file";
    font-weight: normal;
    text-shadow: 0 0 10px #0b0b0b;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    &:after {
      color: #494949;
      content: "Disabled";
    }
  }

  .help {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    background-color: #fff;
    color: #0b0b0b;
    text-align: center;
    border-radius: 16px;
    padding: 5px .2vw;
    font-size: small;
    font-style: italic;
    position: absolute;
    z-index: 1;
    bottom: calc(100% + 10px);
    left: 0;

    &::after {
      content: "";
      position: absolute;
      bottom: -12px;
      left: 50%;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }

  }

  &.call-action .help {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 3s;
  }
}

.page-highlight {
  background-color: rgba(204, 204, 204, 0.29);

  &:after {
    z-index: 10000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #9d9d9d;
    font-size: 57px;
    content: "Drop file";
    text-shadow: 0 0 10px #0b0b0b;
    opacity: 0.1;
  }
}

.highlight {
  background-color: rgba(204, 204, 204, 0.29);
  border-radius: 20px;
  position: relative;
  border: 2px solid #cccccc;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2.5%;
    color: #9d9d9d;
    font-size: 17px;
    content: "Drop file";
    text-shadow: 0 0 10px #0b0b0b;
    opacity: 0.1;
  }

  img {
    opacity: 0.1;
  }
}

.title {
  color: $red;
}

.fondo {
  background-image: url("../../../assets/images/background.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #000;
  width: calc(100% - 4.75rem);
  height: 100%;
  position: absolute;
  top: -5%;
  z-index: -1;
}

.btn-switch {
  border: none;
  color: #959595;
  font-weight: bold;
  text-indent: 0.1vw;
  letter-spacing: 0.2vw;
  text-transform: uppercase;
  font-size: 0.6vw;
  margin-top: 0.5vw;
  cursor: pointer;
}

.btn {
  background: transparent;
  border: none;

  &:hover {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;

    &:after {
      color: #0b0b0b;
      content: "X";
      position: absolute;
      font-weight: lighter;
      font-size: 18em;
      top: -15%;
      left: 0;
      right: 0;
      text-shadow: 0 0 5px white;
      transform: scaleX(1.5);
    }
  }

  &:focus-visible {
    background: transparent;
    border: none;
    outline: 0;
  }

  &:active {
    background-color: transparent !important;
    box-shadow: none;
  }
}

.order-title {
  margin-bottom: 2.1vw;
}

@media (max-width: 758px) {
  .fondo {
    width: 100%;
  }

  .drop-area {
    max-width: 100%;
    padding: .5em 0;
  }

  .btn:disabled {
    &:after {
      font-size: 15em;
      top: -10%;
    }
  }
}

#back-top {
  width: 3.5vw !important;
  height: 3.5vw !important;
  line-height: 4.5vw !important;
  border-radius: 50% !important;
  text-align: center !important;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 1;
  font-size: 3vw;
  border: none;
  outline: none;
  background-color: black;
  color: #d14343;
  cursor: pointer;
  padding: 15px;
  transition: all 0.5s ease 0s !important;
  box-shadow: 0px 2px 15px #d14343;
}

</style>
