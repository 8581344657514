<template>
  <div class="d-flex justify-content-center mb-5">
    <div>
      <select v-model="filter_brand" class="form-control">
        <option value="null">Select Brand</option>
        <option v-for="(item, index) in dropdowns_values.brands" :value="item.make" :key="index">{{
            item.make
          }}
        </option>
      </select>
    </div>
    <div>
      <select v-model="filter_model" class="form-control">
        <option :value="null">Select Model</option>
        <option v-for="(item, index) in dropdowns_values.models" :key="index" :value="item.model">{{
            item.model
          }}
        </option>
      </select>
    </div>
    <div>
      <select v-model="filter_generation" class="form-control">
        <option :value="null">Select Generation</option>
        <option v-for="(item, index) in dropdowns_values.generations" :value="item.generation" :key="index">{{
            item.generation
          }}
        </option>
      </select>
    </div>
    <div>
      <select v-model="filter_engine" class="form-control">
        <option :value="null">Select Engine</option>
        <option v-for="(item, index) in dropdowns_values.engines" :value="item.engine" :key="index">{{
            item.engine
          }}
        </option>
      </select>
    </div>
    <div v-show="dropdowns_values.gearboxes.length">
      <select v-model="filter_gearbox" class="form-control"
              v-bind:class="{'heart': dropdowns_values.gearboxes.length && !filter_gearbox}">
        <option :value="null">Select DSG</option>
        <option v-for="(item, index) in dropdowns_values.gearboxes" :value="item.value" :key="index">{{
            item.text
          }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "VehicleSelector",
  computed: {
    ...mapState('orderSoftwareFile', ['dropdowns_filter', 'dropdowns_values', 'vehicle']),
    filter_brand: {
      get() {
        return this.dropdowns_filter.brand;
      },
      set(value) {
        this.setDropdownsFilter({brand: value});
        this.loadDropdownFilter('models');
      }
    },
    filter_model: {
      get() {
        return this.dropdowns_filter.model;
      },
      set(value) {
        this.setDropdownsFilter({model: value});
        this.loadDropdownFilter('generations');
      }
    },
    filter_generation: {
      get() {
        return this.dropdowns_filter.generation;
      },
      set(value) {
        this.setDropdownsFilter({generation: value});
        this.loadDropdownFilter('engines');
      }
    },
    filter_engine: {
      get() {
        return this.dropdowns_filter.engine;
      },
      set(value) {
        this.setDropdownsFilter({engine: value});
        this.loadDropdownFilter('gearboxes');
        this.loadDataCustomFromFilter(true)
      }
    },
    filter_gearbox: {
      get() {
        return this.dropdowns_filter.gearbox;
      },
      set(value) {
        this.setDropdownsFilter({gearbox: value});
        if (value) {
          this.loadDataCustomFromFilter(false)
        } else {
          this.setDataForCustomOrder({ecu: [], combos: [], tcu: []});
        }
      }
    },
  },
  methods: {
    ...mapMutations('orderSoftwareFile', ['setDropdownsFilter', 'setDataForCustomOrder']),
    ...mapActions('orderSoftwareFile', ['loadDropdownFilter', 'loadDataCustomFromFilter']),
    async mountVehicle() {
      if (this.vehicle.brand && this.dropdowns_values.brands.some(item => item.make === this.vehicle.brand)) {
        this.setDropdownsFilter({brand: this.vehicle.brand});
        await this.loadDropdownFilter('models');
      }
      if (this.vehicle.model && this.dropdowns_values.models.some(item => item.model === this.vehicle.model)) {
        this.setDropdownsFilter({model: this.vehicle.model});
        await this.loadDropdownFilter('generations');
      }
      if (this.vehicle.generation && this.dropdowns_values.generations.some(item => item.generation === this.vehicle.generation)) {
        this.setDropdownsFilter({generation: this.vehicle.generation});
        await this.loadDropdownFilter('engines');
      }
      if (this.vehicle.engine && this.dropdowns_values.engines.some(item => item.engine === this.vehicle.engine)) {
        this.setDropdownsFilter({engine: this.vehicle.engine});
        await this.loadDropdownFilter('gearboxes');
        await this.loadDataCustomFromFilter(true)
      }
    }
  },
  async mounted() {
    await this.loadDropdownFilter('brands');
    await this.mountVehicle();

  }
}
</script>

<style scoped lang="scss">
select {
  background: none;
  border: none;
  width: fit-content;
  color: white;
  height: calc(1.5em + 0.75rem + 2px);

  &:focus {
    color: white;
    background: transparent;
  }

  option {
    background: black;
    color: white;
  }
}
</style>
