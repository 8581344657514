<template>
  <b-card :body-class="bodyClass">
    <i class="material-icons close" @click="$emit('remove', item._id)">close</i>
    <h6 class="title d-flex justify-content-between">
      <span style="max-width: 80%">
        <i class="material-icons float-left">description</i>
        {{ item.title }}
      </span>
      <span class="mr-2 text-decoration-underline">{{ item.file_type }}</span>
    </h6>
    <div class="d-flex justify-content-between">
      <h6 class="p-0 m-0 text-dark">Stage {{ item.stage|stage }}</h6>
      <order-price class="text-danger" :total="userCountryPrice(item.price)"/>
    </div>
  </b-card>
</template>

<script>
import CurrencyPriceView from "@/components/common/CurrencyPriceView";
import {mapGetters} from "vuex";
import OrderPrice from "@/views/dashboard/create-software-order-file/components/OrderPrice";

export default {
  name: "TunedFileViewSmall",
  components: {OrderPrice, CurrencyPriceView},
  props: {
    item: Object,
    bodyClass: {
      type: String,
      default: 'p-4'
    }
  },
  computed: mapGetters('geo', ['userCountryPrice']),
}
</script>

<style scoped lang="scss">
.title {
  font-size: 14px;
  color: #505050;
  font-weight: 700;
  line-height: 19px;
  font-style: italic;
  text-transform: uppercase;

  span {
    max-width: 90%;
  }
}

.price {
  text-align: right;
  font-weight: 300;
  color: #d14343;
  font-size: 14px;
  -webkit-font-smoothing: inherit;
}

.list-group-item {
  border-radius: 0;
  padding: 0.3rem 1.1rem;
}

.def-title {
  letter-spacing: 0.3vw;
  font-weight: 300;
  color: #d14343;
  font-size: 14px;
  -webkit-font-smoothing: inherit;
}
</style>
