<template>
  <div class="my-1 side-filter">
    <div>
      <div class="head-filter d-flex justify-content-between" @click="filterStage.visible = !filterStage.visible">
        <span>
          {{ filterStage.name }}
        </span>
        <span class="text-right">
          <i class="material-icons">{{ filterStage.visible ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</i>
        </span>
      </div>
      <b-collapse :visible="filterStage.visible">
        <div v-for="(filter) of filterStage.items">
          <b-checkbox class="ml-3" v-model="stage_model_filter" :value="filter.value">{{ filter.name }}</b-checkbox>
        </div>
      </b-collapse>
    </div>
    <div v-for="(itemFilter, index) of filter" :key="index">
      <template v-if="itemFilter.items">
        <div class="head-filter d-flex justify-content-between" @click="itemFilter.visible = !itemFilter.visible">
        <span>
          {{ itemFilter.name }}
        </span>
          <span class="text-right">

            <i class="material-icons">{{ itemFilter.visible ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</i>
        </span>
        </div>
        <b-collapse :visible="itemFilter.visible">
          <div v-for="(filter, index) of itemFilter.items" :key="index">
            <b-checkbox class="ml-3" :value="`${itemFilter.value}-${filter.value}`"
                        @change="applyFilter(itemFilter.value, filter.value)"
                        v-model="to_clear">
              {{ filter.name }}
            </b-checkbox>
          </div>
        </b-collapse>
      </template>
      <template v-else>
        <b-checkbox v-model="model_filter" :value="itemFilter.value">{{ itemFilter.name }}</b-checkbox>
      </template>
    </div>
  </div>
</template>

<script>

import {post} from "@/services/api";
import {mapState} from "vuex";

export default {
  name: "SideFilter",
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    filterStage: {
        name: 'Stage',
        visible: true,
        items: [
          {name: 'Drivability', value: 'Stock'},
          {name: 'Stage I', value: '1'},
          {name: 'Stage II', value: '2'},
          {name: 'Stage II+', value: '2.1'},
          {name: 'Stage III', value: '3'},
          {name: 'Stage IV', value: '4'},
        ]
      }
    ,
    filter: [],
    stage_model_filter: [],
    model_filter: [],
    model_objects_filter: {},
    to_clear: []
  }),
  computed: {
    ...mapState('orderSoftwareFile', {
      file_on_server: state => state.file_on_server,
      stage_visibility: state => state.stage_visibility,
    })
  },
  methods: {
    checkVisibility(item){
      if (!this.stage_visibility)
        return true;
      const exist = this.stage_visibility[this.type].find((stage) => stage.id === item.value);
      if (!exist)
        return false;
      return exist.status;
    },
    setVisibility(){
      this.filterStage.items = this.filterStage.items.filter(this.checkVisibility);
    },
    applyFilter(parent, option) {
      const index = this.model_objects_filter[parent].findIndex((el) => el === option)
      if (index < 0) {
        this.model_objects_filter[parent].push(option)
      } else {
        this.model_objects_filter[parent].splice(index, 1)
      }
      this.setFilter()
    },
    setFilter() {
      this.$store.commit('orderSoftwareFile/setFilter', {
        type: this.type,
        options: [...this.model_filter, ...this.getFilterObject()],
        stages: this.stage_model_filter
      })
    },
    getFilterObject() {
      return Object.keys(this.model_objects_filter)
        .filter(el => this.model_objects_filter[el].length)
        .map(key => {
          let obj = {};
          obj[key] = this.model_objects_filter[key];
          return obj
        })
    },
    clear() {
      this.stage_model_filter = [];
      this.model_filter = [];
      this.to_clear = []
      for (const key in this.model_objects_filter) {
        this.model_objects_filter[key] = []
      }
    },
    createFilterTCU() {
      if (this.file_on_server.tcu.stock) {
        const dsgsFamily = this.file_on_server.tcu.stock.dsg_family
        post(`vehicles-dsg/make-filter`, {dsgs: dsgsFamily}, true)
          .then(({data}) => {
            this.createFilter(data)
          })
      }
    },
    createFilterECU() {
      if (this.file_on_server.ecu.stock) {
        const vehicleIds = this.file_on_server.ecu.stock.vehicle_id
        post(`vehicles/make-filter`, {vehicles_ids: vehicleIds}, true)
          .then(({data}) => {
            this.createFilter(data)
          })
      }
    },
    createFilter(data) {
      this.model_objects_filter = {}
      data.filter(el => el.items !== undefined)
        .forEach(el => {
          this.model_objects_filter[`${el.value}`] = []
        })
      this.filter = data.map(el => ({
        ...el,
        visible: false
      }));
    }
  },
  mounted() {
    if (this.type === 'tcu') {
      this.createFilterTCU()
    } else {
      this.createFilterECU()
    }
    this.setVisibility()
  },
  watch: {
    stage_model_filter() {
      this.setFilter()
    },
    model_filter() {
      this.setFilter()
    },
    model_objects_filter: {
      deep: true,
      handler() {
        this.setFilter()
      }
    }
  }
}
</script>

<style scoped>
.head-filter {
  background: rgba(204, 204, 204, 0.18);
  padding: .4em .8em;
  font-weight: bold;
  color: #0b0b0b;
  cursor: pointer;
}

.side-filter {
  max-height: 40vw;
  overflow-y: scroll;
}
</style>
