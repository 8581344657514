<template>
  <div>
    <i v-if="process.found" class="far fa-check-circle text-success"></i>
    <i v-if="process.found === false" class="fas fa-times"></i>
    <i v-if="process.searching" class="fas fa-circle-notch fa-spin"></i>
  </div>
</template>

<script>
export default {
  name: "icon-status",
  props: {
    process: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
  i {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
</style>
