var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"no-shadow rounded-0",attrs:{"body-class":"p-2"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"3","order":_vm.type === 'tcu' && _vm.filterRight? '2':'1'}},[_c('div',{staticClass:"filter"},[_vm._v(" filter ")]),_c('SideFilter',{ref:"sideFilter",class:_vm.sideFilterDisabled(),attrs:{"type":_vm.type}})],1),_c('b-col',{attrs:{"lg":"9","order":_vm.type === 'tcu' && _vm.filterRight? '1':'2'}},[((_vm.type === 'ecu' && _vm.$store.state.orderSoftwareFile.item_seeing_Ecu === null) ||
                    (_vm.type === 'tcu' && _vm.$store.state.orderSoftwareFile.item_seeing_Tcu === null))?_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"fields":['title','stage','price','see_details'],"items":_vm.visible_list,"bordered":"","responsive":"","small":"","hover":""},scopedSlots:_vm._u([{key:"cell(price)",fn:function(ref){
                    var item = ref.item;
return [_c('currency-price-view',{attrs:{"amount":_vm.userCountryPrice(item.price),"hide-decimals":""}})]}},{key:"cell(stage)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(_vm._s(_vm.stages.find(function (el) { return el.value === item.stage; }).title))]}},{key:"cell(see_details)",fn:function(ref){
                    var item = ref.item;
return [_c('b-button',{attrs:{"squared":"","variant":"outline-dark"},on:{"click":function($event){return _vm.setItemSeeing(item)}}},[_vm._v(" See more ... ")])]}}],null,false,366445223)}),_c('b-alert',{attrs:{"show":!_vm.visible_list.length,"variant":"gray"}},[_c('strong',[_vm._v("Ups")]),_vm._v(", sorry we do not have any file with your filter request. Please "),_c('span',{staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":_vm.removeFilters}},[_vm._v("remove filter")]),_vm._v(" or "),_c('span',{staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":_vm.toCustomOrder}},[_vm._v(" try custom order ")])])],1)],1):_c('b-row',[_c('b-col',[_c('file-detail',{attrs:{"is-in-cart":_vm.isTypeOnCart,"item":_vm.type === 'ecu'?_vm.$store.state.orderSoftwareFile.item_seeing_Ecu:_vm.$store.state.orderSoftwareFile.item_seeing_Tcu},on:{"back":_vm.back,"procedToOrder":_vm.proceedToOrder}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }