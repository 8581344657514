<template>
  <div>
    <span v-if="total === 0">Free charge</span>
    <span v-else-if="isNaN(total) || total === 9999">On request</span>
    <template v-else>
      <credits-view v-if="useCredits" :amount="total"/>
      <currency-price-view v-else :amount="total"/>
    </template>
  </div>
</template>

<script>
import CurrencyPriceView from "@/components/common/CurrencyPriceView";
import CreditsView from "@/components/common/CreditsView";
export default {
  name: "OrderPrice",
  components: {CreditsView, CurrencyPriceView},
  props: {
    total: {
      type: Number,
      required: true,
    },
    useCredits: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
