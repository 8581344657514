<template>
  <b-card body-class="px-4 pb-4 pt-3" class="no-shadow no-radius">
    <b-card-title>
      <i class="fas fa-cog" :class="{'fa-spin': isProccesing}"></i>
      Processing File
    </b-card-title>
    <b-row>
      <b-col class="d-flex flex-column">
        <div class="list-process">
          <div class="d-flex justify-content-between p-2">
            <span>VIN Recognized</span>
            <icon-status :process="process.vin"></icon-status>
          </div>
          <div class="d-flex justify-content-between p-2">
            <span>Vehicle in our database</span>
            <icon-status :process="process.vehicle"></icon-status>
          </div>
          <template v-if="ecu.file">
            <div class="d-flex justify-content-between p-2">
              <span>ECU file recognized</span>
              <icon-status :process="process.ecu_file"></icon-status>
            </div>
            <div class="d-flex justify-content-between p-2">
              <span>ECU tune on server</span>
              <icon-status :process="process.ecu_server"></icon-status>
            </div>
          </template>
          <template v-if="tcu.file">
            <div class="d-flex justify-content-between p-2">
              <span>TCU file recognized</span>
              <icon-status :process="process.tcu_file"></icon-status>
            </div>
            <div class="d-flex justify-content-between p-2">
              <span>TCU tune on server</span>
              <icon-status :process="process.tcu_server"></icon-status>
            </div>
          </template>
        </div>
      </b-col>
      <b-col class="d-flex flex-column">
        <div class="list-process">
          <div class="d-flex justify-content-between p-2">
            <span>VIN:</span>
            <span> {{ VIN }}</span>
          </div>
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between p-2">
              <span>Brand: </span>
              {{ vehicle.brand }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between p-2">
              <span>Model: </span>
              {{ vehicle.model }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between p-2">
              <span>Year: </span>
              {{ vehicle.model_year }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between p-2">
              <span>Generation: </span>
              {{ vehicle.generation }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between p-2">
              <span>Engine: </span>
              {{ vehicle.engine }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between p-2">
              <span>Engine Family: </span>
              {{ vehicle.engine_family }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between p-2">
              <span>ECU part #: </span>
              {{ vehicle.ecu_code }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between p-2">
              <span>DSG software: </span>
              {{ vehicle.gearbox_code }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between p-2">
              <span>Gearbox code: </span>
              {{ vehicle.dsg_code }}
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row >
    <b-row class="mt-4">
      <b-col lg="12" v-if="isOrderPending" class="text-center">
        <span class="text-danger m-0">
          There is already an order created with this VIN. You must wait for the order to be completed.
        </span>
      </b-col>
      <template v-else>
        <b-col>
          <div class="text-center mt-auto">
            <b-button variant="danger" size="lg"
                      :class="{'pulse': file_on_server.ecu.files.length || file_on_server.tcu.files.length}"
                      :disabled="(!file_on_server.ecu.files.length && !file_on_server.tcu.files.length || isProccesing)"
                      @click="setProceedAs('fileServer')">
              File server
            </b-button>
          </div>
        </b-col>
        <b-col>
          <div class="text-center mt-auto">
            <b-button variant="primary" size="lg" @click="setProceedAs('custom')" :disabled="isProccesing">
              Order custom tune
            </b-button>
          </div>
        </b-col>
      </template>
    </b-row>
  </b-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {post} from "@/services/api";
import IconStatus from "@/views/dashboard/create-software-order-file/components/IconStatus.vue";

export default {
  name: "ProcessingFile",
  components: {
    IconStatus
  },
  data: () => ({
    process: {
      vin: {found: null, searching: false},
      vehicle: {found: null, searching: false},
      ecu_file: {found: null, searching: false},
      ecu_server: {found: null, searching: false},
      tcu_file: {found: null, searching: false},
      tcu_server: {found: null, searching: false},
    }
  }),

  computed: {
    ...mapState('orderSoftwareFile', {
      VIN: state => state.file_details.VIN,
      ecu: state => state.file_details.ecu,
      tcu: state => state.file_details.tcu,
      file_on_server: state => state.file_on_server,
      vehicle: state => state.vehicle,
      previousOrder: state => state.previous_order.last,
      proceedAs: state => state.proceedAs
    }),
    ...mapGetters('orderSoftwareFile', ['is_combo']),
    ...mapGetters('buySale', ['hasFullAccessOrder']),
    isOrderPending() {
      return this.previousOrder && !['Completed', 'Rejected', 'Cancel'].includes(this.previousOrder.status);
    },
    isProccesing() {
      return Object.values(this.process).map(el => el.searching).some(Boolean)
    }
  },
  methods: {
    ...mapActions('orderSoftwareFile', ['loadServerFiles', 'forCustomOrderByVehicleId', 'loadCombos', 'loadTCUbyDsgFamily', 'loadUncompletedPreOrder']),
    ...mapGetters('orderSoftwareFile',['getFilesOnServer']),
    ...mapMutations('orderSoftwareFile', {
      setProceedStore: 'setProceedAs',
      setVehicle: 'setVehicle',
      setVinVerified: 'setVinVerified',
      setVinSearchResult: 'setVinSearchResult',
      clearOnReUploadFile: 'clearOnReUploadFile',
      clearOnVINchange: 'clearOnVINchange',
    }),
    searchVin() {
      this.process.vin.searching = true;
      this.process.vin.found = null;
      this.process.vehicle.searching = true;
      this.process.vehicle.found = null;
      post('site/find-by-vin', {vin: this.VIN}, true)
        .catch(() => {
          this.process.vin.found = false;
          this.process.vin.searching = false;
          this.process.vehicle.searching = false;
          this.process.vehicle.found = false;
        })
      this.waitResponseVIN()
    },

    waitResponseVIN() {
      this.sockets.subscribe(this.VIN, async (data) => {
        this.process.vin.searching = false;
        this.process.vehicle.searching = false;
        if (!data.result) {
          this.process.vin.found = false;
          this.process.vehicle.found = false;
          this.setVinVerified(false);
          return;
        }
        this.process.vin.found = true;
        let vehicle = {
          id: null,
          brand: data.result.make,
          model: data.result.model,
          model_year: data.result.model_year,
          gearbox_code: data.result.gearbox_code,
          ecu_code: data.result.ecu_code,
          dsg_code: data.result.dsg_code,
        };
        this.setVinSearchResult(vehicle);
        if (data.vehicles.length) {
          const dbVehicle = data.vehicles[0];
          this.process.vehicle.found = true;
          this.setVinSearchResult({
            ...vehicle,
            id: dbVehicle.id,
          })
          vehicle = {
            ...vehicle,
            id: dbVehicle.id,
            generation: dbVehicle.generation,
            engine: dbVehicle.engine,
            engine_family: dbVehicle.engineFamily,
            model: dbVehicle.model,
            dsg_family: dbVehicle.dsg,
            dsg: dbVehicle.dsg.split('_')[0],
            auto_found: true,
          }
          if (data.vehicles.length > 1 || dbVehicle.dsg.includes(';')) {
            vehicle.auto_found = false;
          }
          this.setVinVerified(true)
        } else {
          this.process.vehicle.found = false;
        }
        const getDsgFromMultipleDsg = (server_file_project_dsg, parts_link_dsg) => {
          const isMultipleDsg = parts_link_dsg.includes(';')
          if (!isMultipleDsg) {
            return parts_link_dsg
          }
          return server_file_project_dsg ? server_file_project_dsg.dsg_family : null
        }
        const typeFile = (file_on_server) => {
          return new Promise(resolve => {
            if(this.$store.state.orderSoftwareFile.lastFileUploadedByClient === 'tcu') {
             resolve ( file_on_server.tcu)
            } else{
             resolve(file_on_server.ecu)
            }
          })
        }


        let file_on_server = null
        setTimeout(async () => {
          const values = this.getFilesOnServer()
          file_on_server = await typeFile(values)
           const response = getDsgFromMultipleDsg(file_on_server.stock, vehicle.dsg_family)
          vehicle.dsg_family = response
           this.setVehicle(vehicle);
        },2000)
      });
    },
    setProceedAs(type) {
      this.$store.commit('orderSoftwareFile/showDsgConversionAndFlex', true);
      if (type === 'fileServer') {
        if(this.vehicle){
          this.forCustomOrderByVehicleId(this.vehicle.id)
        }
        const dsg = (this.file_on_server.tcu.stock ? this.file_on_server.tcu.stock.dsg_family : null) || (this.file_on_server.ecu.stock ? this.file_on_server.ecu.stock.dsg_family : null);
        if (this.vehicle.id && this.vehicle.auto_found) {
          // this.forCustomOrderByVehicleId(this.vehicle.id)
        } else if (this.is_combo) {
          this.loadCombos(dsg);
          if (!this.vehicle.auto_found) {
            this.loadTCUbyDsgFamily(dsg);
          }
        }
      } else {
        if (this.vehicle.auto_found) {
          this.forCustomOrderByVehicleId(this.vehicle.id)
        }
      }
      this.setProceedStore(type);
    },
    resetProcess(type) {
      if (type === 'ecu') {
        this.resetEcuProcess();
      } else {
        this.resetTcuProcess();
      }
    },
    resetEcuProcess() {
      this.process.ecu_server = {found: null, searching: false}
      this.process.ecu_file = {found: null, searching: false}
    },
    resetTcuProcess() {
      this.process.tcu_server = {found: null, searching: false}
      this.process.tcu_file = {found: null, searching: false}
    },
    setEcuFailFind(isFileFound = false) {
      this.process.ecu_file.found = isFileFound;
      this.process.ecu_file.searching = false;
      this.process.ecu_server.found = false;
      this.process.ecu_server.searching = false;
    },
  },
  watch: {
    VIN: function (val) {
      if (!val) {
        return;
      }
      this.loadUncompletedPreOrder();
      if (val.length === 17) {
        this.searchVin();
      } else {
        this.process.vin.found = false;
        this.process.vehicle.found = false;
        this.setVinVerified(false);
      }
    },
    ecu: {
      deep: true,
      handler: function (val) {
        if (!val.file)
          return
         console.log('ECU')
        this.$store.state.orderSoftwareFile.lastFileUploadedByClient = 'ecu'
        this.clearOnReUploadFile('ecu');
        this.resetProcess('ecu');
        if (this.ecu.hardware_number && this.ecu.software_version && this.ecu.file) {
          this.process.ecu_file.found = true;
          this.process.ecu_file.searching = false;
          if (this.ecu.hardware_number && this.ecu.software_version) {
            this.loadServerFiles({type: 'ecu', ...this.ecu})
              .then(() => {
                this.process.ecu_server.searching = false;
                this.process.ecu_server.found = this.file_on_server.ecu.files.length > 0;
              })
          }
        } else {
          this.process.ecu_file.searching = true;
          this.process.ecu_server.searching = true;
        }
      }
    },
    tcu: {
      deep: true,
      handler: function (val, oldVal) {
        // if (!val.file || (val.hardware_number === oldVal.hardware_number && val.software_version === oldVal.software_version))
        if (!val.file)
          return
        this.$store.state.orderSoftwareFile.lastFileUploadedByClient = 'tcu'
        this.clearOnReUploadFile('tcu');
        this.resetProcess('tcu');
        this.process.tcu_file.found = true;
        this.process.tcu_server.searching = true;
        if (this.tcu.hardware_number && this.tcu.software_version) {
          this.loadServerFiles({type: 'tcu', ...this.tcu})
            .then(() => {
              this.process.tcu_server.searching = false;
              this.process.tcu_server.found = this.file_on_server.tcu.files.length > 0;
            })
        } else {
          this.process.tcu_server.found = false;
          this.process.tcu_server.searching = false;
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.list-process {
  span {
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
  }

  i {
    font-size: 1rem;
  }
}
</style>
