<template>
  <b-card>
    <div>
      <h2 class="title">{{ item.title }}</h2>
      <h6 v-if="item.hp && item.nm"><span class="sub-title">{{ item.hp }}</span>Hp | <span
        class="sub-title">{{ item.nm }}</span>Nm</h6>
      <div class="d-flex justify-content-between">
        <div class="d-flex pt-3">
          <template v-if="item.recommended">
            <span class="check mr-1" style="background: #fff">✔</span> Recommended by TVS
          </template>
        </div>
        <div class="price">
          <currency-price-view :amount="userCountryPrice(item.price)"
                               hide-decimals/>
        </div>
      </div>
      <p>{{ item.description }}</p>
    </div>
    <b-row style="position: relative">
      <template v-for="def of definitions">
        <b-col lg="6" v-if="def.values.length">
          <!--              <h2 class="def-title">{{ def.title }}</h2>-->
          <b-list-group>
            <b-list-group-item v-for="option of def.values" class="d-flex justify-content-between"
                               v-tippy="{ placement : 'top',  arrow: true, onShow: () => option? option.description !== null : false }"
                               :content="option.description">
              <span>{{ option.name }}</span>
              <span :class="{'check': option.value === true}">{{
                  (option.value === true ? '✔' : option.value)
                }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </template>
    </b-row>
    <div class="text-right mt-2">
      <b-button @click="$emit('back')" class="btn-order" variant="outline-dark">
        <i class="material-icons">arrow_back_ios</i>
        Back to list
      </b-button>
      <b-button v-if="!isCombo" @click="order(false)" class="btn-order" :class="{'red-pulse': !isInCart}"
                variant="danger">Order
      </b-button>
      <b-button v-else @click="order(true)" class="btn-order" :class="{'red-pulse': !isInCart}" variant="danger"
                v-tippy="{ placement : 'top',  arrow: true}"
                content="Use this to create a combo">Add to order
      </b-button>
    </div>
  </b-card>
</template>

<script>
import CurrencyPriceView from '../../../../components/common/CurrencyPriceView.vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {FILE_OPTIONS_DEFINITION} from '../../../../constants';

export default {
  name: 'FileDetail',
  components: {CurrencyPriceView},
  props: {
    item: {
      type: Object,
      required: true,
    },
    isInCart: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    definitions: FILE_OPTIONS_DEFINITION(),
  }),
  computed: {
    ...mapState('orderSoftwareFile', {
      key_stock_file: (state) => state.file_details.ecu.cmd_file_key,
    }),
    ...mapGetters('geo', ['userCountryPrice']),
    ...mapGetters('orderSoftwareFile', {
      isCombo: 'is_combo',
    }),
  },
  methods: {
    ...mapActions('orderSoftwareFile', ['loadCombos']),
    ...mapMutations('orderSoftwareFile', ['addServerFile']),
    setOptions() {
      this.definitions.forEach(item => {
        item.values = this.item.visual_options.filter(option => {
          return option.location === item.field;
        });
      });
    },
    async order() {
      if(this.item.file_type === 'ECU'){
        this.$store.state.orderSoftwareFile.item_seeing_Tcu = null
      }else{
        this.$store.state.orderSoftwareFile.item_seeing_Ecu = null
      }
      this.addServerFile({
        key_stock_file: this.key_stock_file,
        ...this.item
      });
      if (this.isCombo) {
        this.$eventBus.$emit('order-combo');
        //TODO: check if other files o custom section is no selected to prevent scroll to file list and instead send
        this.$eventBus.$emit('scroll', 'file-list');
      } else {
        this.$emit('proceedToOrder', {
          original_price: this.item.price,
          price: this.userCountryPrice(this.item.price),
        });
      }
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>

<style scoped lang="scss">
.sub-title {
  font-weight: 300;
  color: #d14343;
  font-size: 1.5em;
}

.card {
  background: #ececec;
  position: relative;
  min-height: 24em;
}

.title {
  font-size: 29px;
  color: #505050;
  font-weight: 700;
  line-height: 29px;
  font-style: italic;
  text-transform: uppercase;
}

.img-stage {
  height: 250px;
  width: 350px;
  //background-size: 250px 250px;
  background-repeat: no-repeat;

  &.ecu {
    background-image: url("../../../../assets/images/ecu_stages.png");

    &.s-1 {
      background-position: left -349px top 0;
    }

    &.s-2 {
      background-position: left -698px top 0;
    }

    &.s-21 {
      background-position: left -1047px top 0;
    }

    &.s-3 {
      background-position: left -1396px top 0;
    }

    &.s-4 {
      background-position: left -1745px top 0;
    }
  }

  &.tcu {
    background-image: url("../../../../assets/images/tcu_stages.png");

    &.s-1 {
      background-position: left -349px top 0;
    }

    &.s-2 {
      background-position: left -698px top 0;
    }

    &.s-21 {
      background-position: left -1047px top 0;
    }

    &.s-3 {
      background-position: left -1396px top 0;
    }

    &.s-4 {
      background-position: left -1745px top 0;
    }

  }
}

.list-group-item {
  border-radius: 0;
  padding: 0.5rem 1.1rem;
}

.def-title {
  letter-spacing: 0.3vw;
  font-weight: 300;
  color: #d14343;
  font-size: 14px;
  -webkit-font-smoothing: inherit;
}

.check {
  text-align: center;
  width: 22px;
  height: 22px;
  background: #ececec;
  border-radius: 50%;
  color: #d14343;
}

.btn-order {
  color: var(--dark);
  border-color: var(--dark);
  padding: .5vw 1.5vw;
  border-radius: 0;
  height: 3.2em;

  &.btn-danger {
    color: var(--light);
    border-color: var(--danger);
  }

  &:hover {
    color: var(--light);
  }
}

.price {
  font-size: 1.6vw;
  color: #d14343;
  font-weight: 400;
}
</style>
