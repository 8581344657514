<template>
  <div class="box-container">
    <div class="content">
      <div>
        <table class="w-100">
          <tr>
            <td class="text-center">
              <span class="lightGray">ECU:</span>
              <div>+</div>
            </td>
            <td class="text-center">
              <img alt="Card image" class="combimage" src="@/assets/images/stages/STG.svg">
              <hr class="combo-hr"/>
            </td>
            <td style="vertical-align: sub" class="pl-3">
              <img :src="combo.left" alt="Card image" class="combimage">
            </td>
            <td class="price-combo">
              <currency-price-view v-if="!isNaN(combo.price)" :amount="userCountryPrice(combo.price)"
                                   hide-decimals/>
              <span v-else>On request</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="lightGray">DSG:</span>
            </td>
            <td>
              <img alt="Card image" class="combimage" src="@/assets/images/stages/STG.svg">
            </td>
            <td class="pl-3">
              <img :src="combo.right" alt="Card image" class="combimage">
            </td>
            <td class="percent-combo">
              <div v-if="combo.discount > 5">
                <h2>{{ combo.discount }} </h2>
                <div>
                  <div>%</div>
                  <div>OFF</div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="mb-4">
      <div class="image__title">{{ combo.title }}</div>
      <div class="d-flex justify-content-between">
        <p class="image__description">
          {{ combo.description }}
        </p>
        <div class="ml-2 mt-auto" v-if="false">
          <b-button variant="outline-light" class="continue"
                    @click.stop="showDetail('ECU + DSG', combo.id)">
            ORDER
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyPriceView from "@/components/common/CurrencyPriceView";
import {mapGetters} from "vuex";

export default {
  name: "ComboView",
  components: {CurrencyPriceView},
  props: {
    combo: Object
  },
  computed: {
    ...mapGetters('geo', ['userCountryPrice'])
  }
}
</script>

<style scoped lang="scss">
$light-gray: #959595;
.box-container {
  width: 95%;
  margin: auto;
  padding-bottom: 1vw;
}
.price-combo {
  width: 100%;
  text-align: right;
  font-size: 1.6vw;
  color: white;
}
.combimage {
  border-radius: 0 !important;
  max-height: 0.9vw !important;
  padding-left: .5vw;
  filter: invert(42%) sepia(93%) saturate(0%) hue-rotate(87deg) brightness(160%) contrast(119%);
}
.lightGray {
  color: $light-gray;
  font-size: 1.1em;
}
.combo-hr {
  margin-top: 0.7vw !important;
  margin-bottom: 0.4vw !important;
  border-top: 1px solid;
  border-color: $light-gray;
  width: 80%;
}
.image__description {
  font-size: 0.8em;
  margin-top: 0.25em;
}
.content {
  tr {
    display: block;
    min-height: 30px;
  }
}
.percent-combo {
  width: 100%;
  text-align: right;
  color: rgb(209, 67, 67) !important;

  & > div > div > div:first-child {
    font-size: 1.2vw
  }

  & > div {
    margin-right: 1.2vw;
    display: flex;
    justify-content: flex-end;

    & > div {
      margin-top: -.3vw;
    }

    & > h2 {
      color: rgb(209, 67, 67);
      padding: 0;
      margin: 0;
    }

    > div > div:last-child {
      font-size: 0.55vw;
      margin-top: -.3vw;
    }
  }
}
</style>
